import * as types from '../actions/types'

const initialState = {
    isLoading: false,
    abis: [],
    error: {},
}

export default function abiReducer(state = initialState, action) {
    switch(action.type) {
        case types.GET_ABIS:
            return {...state, isLoading: true}
        case types.SET_ABIS:
            return {...state, isLoading: false, abis: action.payload}
        
        case types.ADD_ABI:
            return {...state, isLoading: true}
        case types.ABI_ADDED:
            return {...state, isLoading: false, abis: [...state.abis, {...action.payload, abi: JSON.parse(action.payload.abi)}]}
        default:
            return state; 
    }
}