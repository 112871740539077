import './empty.css';
import React  from 'react';
import { EMPTY_PAGE_TEXTS } from '../../common/constants';

function Empty(props) {
    const { type } = props;
    let text = '';

    text = EMPTY_PAGE_TEXTS[type]

    return(
        <div className='empty-container'>
            <div className='empty-image'>
                <img className="arrow" src={`/img/icons/dont_have_${type}.svg`} alt="empty"/>  
            </div>
            <div className='empty-description'>
                {text}
            </div>
        </div>
    )
}


export default Empty