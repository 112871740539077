import './notifications.css';
import React  from 'react';
import {connect} from 'react-redux'
import {notification, message} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useEffect} from 'react';
import {bindActionCreators} from 'redux'
import * as errorActions from "../../actions/errorActions"
import isEmpty from '../../helpers/isEmpty';


const openNotification = (message, description) => {
    notification.open({
      message ,
      description,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

function Notifications(props) {
    const { error, success } = props;

    useEffect(()=>{
        if(!isEmpty(success)) {
            openNotification(success.message, success.description);
            props.errorActions.cleanError()
        }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ success])

   useEffect(() => {
    if(!isEmpty(error)) {
        message.error(error)
        props.errorActions.cleanError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    return(
        <div></div>
    )
}

const mapStateToProps = state => ({
    error: state.errorReducer.error,
    success: state.errorReducer.success,
})


const mapDispatchToProps = dispatch => ({
    errorActions: bindActionCreators(errorActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)