import * as types from '../actions/types'

const initialState = {
    isLoading: false,
    extensions: [],
    project_extensions: [],
    extension: {},
    error: {},
}

export default function extensionsReducer(state = initialState, action) {
    switch(action.type) {
        case types.GET_EXTENSIONS:
            return {...state, isLoading: true}
        case types.SET_EXTENSIONS:
            return {...state, isLoading: false, extensions: action.payload}
        case types.GET_EXTENSIONS_BY_PROJECT:
            return {...state, isLoading: true}
        case types.SET_EXTENSIONS_BY_PROJECT:
            return {...state, isLoading: false, project_extensions: action.payload}
        case types.GET_EXTENSION:
            return {...state, isLoading: true}
        case types.ADD_EXTENSION:
            return {...state, isLoading: true}
        case types.ADDED_EXTENSION:
            return {...state, isLoading: false}
        case types.SET_EXTENSION:
            return {...state, isLoading: false, extension: action.payload}
        default:
            return state; 
    }
}