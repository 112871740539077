import * as types from '../actions/types'

const initialState = {
    isLoading: false,
    permissions: [],
    permissionBuilds: [],
    error: {},
}

export default function permissionReducer(state = initialState, action) {
    switch(action.type) {
        case types.GET_PERMISSIONS:
            return {...state, isLoading: true}
        case types.PERMISSIONS_RECEIVED:
            return {...state, isLoading: false, permissions: action.payload}
        
        case types.GET_PERMISSION_BUILDS:
            return {...state, isLoading: true}
        case types.SET_PERMISSION_BUILDS:
            return {...state, isLoading: false, permissionBuilds: action.payload}
        
                
        case types.ADD_PERMISSION:
            return {...state, isLoading: true}
        case types.PERMISSION_ADDED:
            return {...state, isLoading: false, permissions: [...state.permissions, action.payload]}

        case types.UPDATE_PERMISSION:
            return {...state, isLoading: true}
        case types.PERMISSION_UPDATED:
            return {...state, isLoading: false, permissions: state.permissions.map(item => item.id == action.payload.id ? action.payload : item)}
        
        case types.DELETE_PERMISSION:
            return {...state, isLoading: true}
        case types.PERMISSION_DELETED:
            return {...state, isLoading: false, permissions: state.permissions.filter(item => item.id != action.payload.id)}
        default:
            return state; 
    }
}