import {all, put, takeLatest} from "redux-saga/effects"
import * as types from "../actions/types"
import axios from 'axios'

import { ENDPOINT } from "../config"

function* getPermissions(action) {
    try {
        const permissions = yield axios.get(`${ENDPOINT}/dev-center/permissions/`).then(res => res.data)
        yield put({type: types.PERMISSIONS_RECEIVED, payload: permissions.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getPermissionBuilds(action) {
    const {data} = action
    try {
        const permissions = yield axios.get(`${ENDPOINT}/dev-center/permissions/builds/${data}`).then(res => res.data)
        yield put({type: types.SET_PERMISSION_BUILDS, payload: permissions.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* addPermission(action) {
    const {data} = action
    try {
        const added = yield axios.post(`${ENDPOINT}/dev-center/permissions/`, data).then(res => res.data)
        yield put({type: types.PERMISSION_ADDED, payload: added.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* updatePermission(action) {
    const {data} = action
    try {
        const updated = yield axios.put(`${ENDPOINT}/dev-center/permissions/${data.id}`, data).then(res => res.data)
        yield put({type: types.PERMISSION_UPDATED, payload: updated.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* deletePermission(action) {
    const {data} = action
    try {
        const deleted = yield axios.delete(`${ENDPOINT}/dev-center/permissions/${data}`).then(res => res.data)
        yield put({type: types.PERMISSION_DELETED, payload: deleted.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

export function* permissionSaga() {
    yield all([
        yield takeLatest(types.GET_PERMISSIONS, getPermissions),
        yield takeLatest(types.GET_PERMISSION_BUILDS, getPermissionBuilds),
        yield takeLatest(types.ADD_PERMISSION, addPermission),
        yield takeLatest(types.UPDATE_PERMISSION, updatePermission),
        yield takeLatest(types.DELETE_PERMISSION, deletePermission)
    ])
}