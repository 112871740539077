import './App.css';
import Signup from './containers/signup';
import Signin from './containers/signin';
import {Provider} from 'react-redux'
import configureStore from './store';
import 'antd/dist/antd.min.css'
import './style/common.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Dashboard from './containers/dashboard';
import Extensions from './containers/extensions';
import ExtensionDitail from './containers/extensionDetail';
import BuildList from './containers/buildList';
import Builds from './containers/builds';
import setAuthToken from './helpers/setAuthToken.js';
import jwt_decode from 'jwt-decode'
import * as types from './actions/types'
import PrivateRoute from './privateRoute';
import AdminRoute from './adminRoute';
import BuildDetail from './containers/build-detail';
import CreateApp from './containers/createApp';
import CreateBuild from './containers/create-build';
import Projects from './containers/projects';
import Project from './containers/project';
import Notifications from './components/notifications';
import Permission from './containers/permission';
import Abi from './containers/abi';
import Protobuf from './containers/protobuf';
import Formats from './containers/formats';

const store = configureStore()

if(localStorage.token) {
  const {token, user} = localStorage
  setAuthToken(token)
  const decoded = jwt_decode(token)
  store.dispatch({type: types.SET_CURRENT_USER, payload: {decoded, user: JSON.parse(user)}})
  const currentTime = Date.now() / 1000
  if(decoded.exp < currentTime) {
    localStorage.removeItem('token')
    setAuthToken(false)
    store.dispatch({type: types.SET_CURRENT_USER, payload: {}})
  }
}

function App() {
  return (
    <Provider store={store}>
       <BrowserRouter>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} >
                  <Route path="projects" element={<Projects/>}/>
                  <Route path="formats" element={<Formats/>}/>
                  <Route path="projects/:id" element={<Project/>}/>
                  <Route path="projects/build/:id" element={<BuildDetail/>}/>
                  <Route path="permissions" element={<Permission/>}/>
                  <Route path="abi" element={<Abi/>}/>
                  <Route path="protobuf" element={<Protobuf/>}/>
                  <Route path="apps" element={<Extensions/>}/>
                  <Route path="apps/:id" element={<ExtensionDitail/>}/>
                  <Route path="create-app" element={<CreateApp/>}/>
                  <Route path="create-build" element={<CreateBuild/>}/>
                  <Route path="builds" element={<Builds/>}>
                    <Route path="app/:id" element={<BuildList/>}/>
                  </Route>
              </Route>
            </Route>
            <Route element={<AdminRoute />}>
              <Route path="/" element={<Dashboard />} >
                  <Route path="projectss" element={<Projects/>}/>
              </Route>
            </Route>
            <Route path="signup" element={<Signup/>}/>
            <Route path='signin' element={<Signin/>}/>
          </Routes>
        </BrowserRouter> 
        <Notifications/>
    </Provider>
  );
}

export default App;