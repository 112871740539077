import {connect} from 'react-redux'
import ExtensionCard from '../../components/extensionCard';
import './extensions.css'
import React from 'react';
import {  Spin } from 'antd';
import Empty from '../../components/empty';

function Extensions(props) {
    const {project_extensions, isLoading} = props

    const ext = project_extensions
    .map((i, index) => 
        <div className='project-name' key={index}>
            {/* <div className='project-name-inner'>{i.name}</div> */}
            {i.extensions.length ? 
            <div className='project-priject-extension'>{i.extensions.map((exxt, index) =>(<ExtensionCard key={index} extension={exxt}/>))}</div> :
            (<Empty type={"desc"}/>)
            }
        </div>
    )
    

    return(
        <div className="extensions">
            {isLoading ? (<Spin tip="Loading..." size='large'/>) :
            (<div className="extensions-container">
                {ext}
            </div>)}
        </div>
    )
}

const mapStateToProps = state => ({
   
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Extensions)