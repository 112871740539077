import './bigCard.css'
import {Link} from 'react-router-dom'

function BigCardHeader(props) {
    const {id, logo, name} = props
    return(
        <div className="big-card--header">
            <div className="extension-logo">
                <img alt="logo" src={logo}></img>
            </div>
            <div className="extension-name">
                <Link to={`/apps/${id}`}>{name}</Link>
            </div>
        </div>
    )
}

export default BigCardHeader