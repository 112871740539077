import './bigCard.css'

function BigCard(props) {
    return(
        <div className="big-card">
            {props.children}
        </div>
    )
}

export default BigCard