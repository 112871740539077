
import { connect } from "react-redux";
import {Navigate, Outlet} from 'react-router';


const PrivateRoute=({auth})=>(
 
                auth.isAuth
                    ? (
                        <Outlet/>
                    ) : (
                        <Navigate to="/signin"/>
                    )
            
);


const mapStateToProps=state=>({
    auth:state.auth
});

export default connect(mapStateToProps)(PrivateRoute);