import ListItem from "./list-item"

export default function List({items}) {
    // console.log(items)
    const listItems = items.map((item, index) => 
            <ListItem 
                key={index} 
                title={item.readable_name} 
                type={item.type} 
                description={JSON.stringify(item.parameters)}
                actions={item.actions}
                typeStyle={item.typeStyle}
    />)
    return(
        <div className="list">
            {listItems}
        </div>
        
    )
}