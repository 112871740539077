import * as types from './types'

export function signUp(data, navigate) {
    return {type: types.SIGN_UP, data, navigate}
}

export function signIn(data, navigate) {
    return {type: types.SIGN_IN, data, navigate}
}

export function getToken() {
    return {type: types.GET_TOKEN}
}

export function logout(navigate) {
    return {type: types.LOGOUT, navigate}
}