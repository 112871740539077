import './modal.css'
import {ReactComponent as Close} from '../../assets/close.svg';

function CustomModal(props) {
    const {isVisible, onCancel, color = '#00A3FF', children, title, hideClose = false, width='600px'} = props
    // console.log(hideClose)
    return(
        <div className="modal" style={{display: isVisible ? 'flex' : 'none'}}>
            <div className="modal-overlay" onClick={onCancel}></div>
            <div className="modal-content"  style={{borderTop: `16px solid ${color}`, width}}> 
                {!hideClose && <div className='modal-close' onClick={onCancel}>
                    <Close fill='#000'/>
                </div>}
                {title && <div className='modal-header'>
                    {title}
                </div>}
               {children}
            </div>
        </div>
    )
}

export default CustomModal