import * as types from '../actions/types'

const initialState = {
    isLoading: false,
    error: {},
    projects: [],
    project: {},
    nets: [],
    formats: [],
    project_permissions: [],
    project_nets: [],
    project_formats: [],
    token: ''    
}

export default function projectReducer(state = initialState, action) {
    switch(action.type) {
        case types.GET_PROJECTS:
            return {...state, isLoading: true}
        case types.SET_PROJECTS:
            return {...state, isLoading: false, projects: action.payload}

        case types.GET_PROJECT:
            return {...state, isLoading: true}
        case types.SET_PROJECT:
            return {...state, isLoading: false, project: action.payload}

        case types.ADD_PROJECT:
            return {...state, isLoading: true}
        case types.ADDED_PROJECT:
            return {...state, isLoading: false, projects: [...state.projects, action.payload]}

        case types.DELETE_PROJECT:
            return {...state, isLoading: true}
        case types.DELETED_PROJECT:
            return {...state, isLoading: false, projects: state.projects.filter(item => item.id !== action.payload)}
      
        case types.UPDATE_PROJECT:
            return {...state, isLoading: true}
        case types.UPDATED_PROJECT:
            return {...state, isLoading: false, projects: state.projects.map(item => item.id === action.payload.id ? action.payload : item)}
        
        case types.GET_NETS:
            return {...state, isLoading: true}
        case types.SET_NETS:
            return {...state, isLoading: false, nets: action.payload}

        case types.GET_FORMATS:
            return {...state, isLoading: true}
        case types.SET_FORMATS:
            return {...state, isLoading: false, formats: action.payload}

        case types.ADD_FORMAT:
            return {...state, isLoading: true}
        case types.FORMAT_ADDED:
            return {...state, isLoading: false, formats: [...state.formats, action.payload]}

        case types.GET_PROJECT_TOKEN:
            return {...state, isLoading: true}
        case types.SET_PROJECT_TOKEN:
            return {...state, isLoading: false, token: action.payload}

        case types.GET_PROJECT_PERMISSIONS:
            return {...state, isLoading: true}
        case types.SET_PROJECT_PERMISSIONS:
            return {...state, isLoading: false, project_permissions: action.payload}

        case types.GET_PROJECT_NETS:
            return {...state, isLoading: true}
        case types.SET_PROJECT_NETS:
            return {...state, isLoading: false, project_nets: action.payload}
            
        case types.GET_PROJECT_FORMATS:
            return {...state, isLoading: true}
        case types.SET_PROJECT_FORMATS:
            return {...state, isLoading: false, project_formats: action.payload}
        
        case types.ADD_NETS_TO_PROJECT:
            return {...state, isLoading: true}
        case types.ADDED_NETS_TO_PROJECT:
            return {...state, isLoading: false, project_nets: [...action.payload]}

        case types.ADD_FORMATS_TO_PROJECT:
            return {...state, isLoading: true}
        case types.ADDED_FORMATS_TO_PROJECT:
            return {...state, isLoading: false, project_formats: [...action.payload]}

        case types.ADD_PERMISSIONS_TO_PROJECT:
            return {...state, isLoading: true}
        case types.ADDED_PERMISSIONS_TO_PROJECT:
            return {...state, isLoading: false, project_permissions: [...action.payload]}
        default:
            return state; 
    }
}