import './button.css'

export default function Button({children, onClick, type, position, hasGap, style, noWrapper = false}) {
    return(
       <div className={`${!noWrapper && 'button-wrapper'} button-${position} ${hasGap ? 'button-margin' : ''}`}>
        <button style={style} type='submit' onClick={onClick} className={`button ${type}-button`}>
            {children}
        </button>  
       </div> 
    )
}