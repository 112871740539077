import * as types from '../actions/types'
import isEmpty from '../helpers/isEmpty'

const initialState = {
    isLoading: false,
    user: {},
    error: {},
    token: '',
    isAuth: false,
    isLoadingToken: false,

}

export default function authReducer(state = initialState, action) {
    switch(action.type) {
        case types.SIGN_UP:
            return {...state, isLoading: true}
        case types.SIGN_UP_SUCCESS:
            return {...state, isLoading: false, user: action.payload}
        case types.GET_TOKEN:
            return {...state, isLoadingToken: true}
        case types.SET_TOKEN:
            return {...state, isLoadingToken: false, token: action.payload}
        case types.SIGN_IN:
            return {...state, isLoading: true}
        case types.SET_CURRENT_USER:
            return {...state, isLoading: false, isAuth: !isEmpty(action.payload), user: action.payload.user}
        default:
            return state; 
    }
}