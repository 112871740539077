import {useState} from 'react'
import {Form, Select, Input, Tooltip, Upload} from 'antd'
import { ChromePicker } from 'react-color';
import MainCard from '../../components/main-card'
import CustomModal from '../../components/modal'
import {
    InfoCircleOutlined,
    InboxOutlined
  } from '@ant-design/icons';
import {ReactComponent as Close} from '../../assets/close.svg';

const {TextArea} = Input
const {Dragger} = Upload



function BuildDescription({onChangeDescription, descriptionOptions, setPreview, preview, specificFields, setSpecificFields, appType, onKeyUp, setFormValue}) {
    const [modalVisible, setModalVisible] = useState(false)
    const props_img = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        async onChange(info) {
          const logo = await getBase64(info.file);     
          setPreview({...preview, logo:  logo})
          setFormValue((prev) => {return {...prev, logo: logo}})
        },
        onDrop(e) {
          // console.log('Dropped files', e.dataTransfer.files);
        },
      };

      const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });

    return (
        <div>
            <div className='form-group-label-group'>
                <div className="extension-label">Description (use existing description or create a new one below)</div> 
                <Form.Item name={"extension_id"} stile={{width:"100px"}} rules={[{ required: false }]}>
                <Select
                    showSearch={false}
                    placeholder="Select a description"
                    optionFilterProp="children"
                    onChange={onChangeDescription}
                    // onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                >
                {descriptionOptions}
                </Select>
                </Form.Item>
            </div>
            <div className='form-group-label-group'>
                <div className="extension-label">Application name * </div> 
                <Form.Item name={"name"}  rules={[{ required: true }]}
                onChange={(e) => {
                    setPreview({...preview, name: e.target.value})
                }}
                >
                <Input
                    name={'name'}
                    onKeyUp={onKeyUp}
                    placeholder="i.e Dragon Swap"
                    suffix={
                    <Tooltip title="Text / Name of app">
                        <InfoCircleOutlined />
                    </Tooltip>
                    }
                />
                </Form.Item>
            </div>
            {(appType === 'appSer' || appType === 'app') && <><div className='form-group-label-group'>
                <div className="extension-label">Short description (app type) * </div> 
                <Form.Item name={"short_description"} rules={[{ required: true }]}
                onChange={(e) => {
                    setPreview({...preview, shortDescription: e.target.value})
                }}
                >
                <Input
                    name={'short_description'}
                    onKeyUp={onKeyUp}
                    placeholder="i.e Privacy-preserving DeFi"
                    suffix={
                    <Tooltip title="Application type/category to understand the main functionality ">
                        <InfoCircleOutlined />
                    </Tooltip>
                    }
                />
                </Form.Item>
            </div>
            <div className='form-group-label-group'>
                <div className="extension-label">App card description </div> 
                <Form.Item name={"card_description"} 
                    onChange={(e) => {
                setPreview({...preview, cardDescription: e.target.value})
                }}
                >
                <Input
                    placeholder="i.e Earn more with your staking via autocompounding your rewards!"
                    suffix={
                    <Tooltip title="Information will be displayed on application label in C1 app marketplace.">
                        <InfoCircleOutlined />
                    </Tooltip>
                    }
                />
                </Form.Item>
            </div>
            <div className='form-group-label-group'>
                <div className="extension-label-others">Extended description  * </div> 
                <Form.Item name={"description"} rules={[{ required: true }]}
                onChange={(e) => {
                setPreview({...preview, description: e.target.value})
                }}
                >
                    <TextArea 
                    name={'description'}
                    onKeyUp={onKeyUp}
                    placeholder="Provide a detailed description of your item"
                    allowClear style={{ height: 120 }}/>
                </Form.Item>
            </div>
            </>}
            <div className='form-group-label-group'>
                <div className="extension-label-others">Logo and background color*</div> 
                <div className="form-group-content">
                <Form.Item name={"logo"} rules={[{ required: true }]} style={{ height: "fit-content", width: (appType === 'appSer' || appType === 'app') ? "70%" : "100%", marginRight:  (appType === 'appSer' || appType === 'app') ? "25px" : "0"}} >
                    <Dragger {...props_img}  beforeUpload={() => false } >
                    <p className="ant-upload-drag-icon">
                       {preview.logo ? <div className='main-card--logo'>
                            <img alt={'logo'} src={preview.logo}/>
                            <div className='main-card--logo---delete'>
                              <Close fill="#fff" style={{width: '10px', height: "10px"}}/>
                            </div>
                         </div> : <InboxOutlined />}
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                    </p>
                    </Dragger>
                </Form.Item>
                {(appType === 'appSer' || appType === 'app') && <>
                <Form.Item name={"background_color"} >
                    <ChromePicker 
                        color={specificFields.color}
                        value={specificFields.pickerValue}
                        onChange={(e) => {
                        setSpecificFields({...specificFields, color: e.hex})
                        setPreview({...preview, color: e.hex})
                        }}
                    />
                </Form.Item>
                </>}
                </div>
            </div>
            {(appType === 'appSer' || appType === 'app') && <>
            <div className='form-group-label-group'>
                <div className="extension-label">Video  url</div> 
                <Form.Item name={"url_video"}
                onChange={(e) => {
                    setPreview({...preview, video: e.target.value})
                }}
                >
                    <Input
                    placeholder="https://youtube.com"
                    suffix={
                        <Tooltip title="Text / Video url">
                        <InfoCircleOutlined />
                        </Tooltip>
                    }
                    />
            </Form.Item>
            </div>
           
            <div className='project-preview'>
                <div className='project-preview--title'>
                    <div>
                    Preview
                    </div>
                    <span className='show-info' onClick={() => setModalVisible(true)}>Show all info</span>
                </div>
                <div className='project-preview--body'>
                    <MainCard
                        logo={preview.logo}
                        name={preview.name}
                        cardDescription={preview.cardDescription}
                        shortDescription={preview.shortDescription}
                        color={preview.color}
                        readMore={() => setModalVisible(true)}
                    />
                </div>     
            </div>
            </>}
            <CustomModal
            isVisible={modalVisible}
            onCancel={() => setModalVisible(false)}
            color={preview.color}
            title={''}
          >
            <div className='app-head'>
                    <div>
                        <div className='app-logo'>
                            <img alt="logo" src={preview.logo ?? 'img/icons/default.svg'}/>
                        </div>
                        <div className='app-title'>
                            <div className='app-name'>
                                {preview.name}
                                <img src={'img/icons/check.svg'} alt="check"/>
                            </div>
                            <div className='app-short'>
                                {preview.shortDescription}
                            </div>
                        </div>
                    </div>               
                   
                </div>
               
               <hr style={{border: '1px #8B9BC7', borderStyle: 'none none dashed' }}/>
               <div className='app-body'>
               {preview.networks ?<> <div className='app-body--head'>
                        <div className='app-label'>Networks</div>
                        <span className='app-show--more'>Show more</span>
                    </div>
                        <div className="networks">
                            <div className='network-item'>
                                <div className="network-img">
                                    <img src="img/icons/default.svg" alt=""/>
                                </div>
                                <div className='network-name'>
                                    Cosmos.network
                                </div>
                            </div>
                            <div className='network-item'>
                                <div className="network-img">
                                    <img src="img/icons/default.svg" alt=""/>
                                </div>
                                <div className='network-name'>
                                    Cosmos.network
                                </div>
                            </div>
                            <div className='network-item'>
                                <div className="network-img">
                                    <img src="img/icons/default.svg" alt=""/>
                                </div>
                                <div className='network-name'>
                                    Cosmos.network
                                </div>
                            </div>
                            <div className='network-item'>
                                <div className="network-img">
                                    <img src="img/icons/default.svg" alt=""/>
                                </div>
                                <div className='network-name'>
                                    Cosmos.network
                                </div>
                            </div>
                            <div className='network-item'>
                                <div className="network-img">
                                    <img src="img/icons/default.svg" alt=""/>
                                </div>
                                <div className='network-name'>
                                    Cosmos.network
                                </div>
                            </div>
                            <div className='network-item'>
                                <div className="network-img">
                                    <img src="img/icons/default.svg" alt=""/>
                                </div>
                                <div className='network-name'>
                                    Cosmos.network
                                </div>
                            </div>
                    </div></> : <></>}
                    {preview.description ? <><div className='app-body--head'>
                        <div className='app-label'>Description</div>
                    </div>
                    <div className="app-desc">  
                        {preview.description}
                    </div> </> : <></>
                    }
                    {
                      preview.video ? <>
                        <div className='app-body--head'>
                        <div className='app-label'>Guide</div>
                    </div>
                    <div className="app-video">  
                        <iframe style={{borderRadius: '12px', border: 'none'}} src={preview.video} width={'100%'} height={'450'}/>
                    </div>
                        </> : <></>
                    }
                </div>
          </CustomModal>  
        </div>
    )
}

export default BuildDescription