import * as types from './types'

export function getExtensions() {
    return {type: types.GET_EXTENSIONS}
}

export function getExtensionsByProject(data) {
    return {type: types.GET_EXTENSIONS_BY_PROJECT, data}
}

export function getExtension(data) {
    return {type: types.GET_EXTENSION, data}
}

export function addExtension(data, navigate) {
    return {type: types.ADD_EXTENSION, data, navigate}
}

