import TableCell from "../table-cell"
import './table-row.css'
import {useState} from 'react'
import Table from "../index"

export default function TableRow(props) {
    const {item, columns, style, expandable, expandKey, expandColumns, needDeploy} = props
    const [isVisible, setIsVisible] = useState(false)
    const cells = columns.map((col, index) => 
        <TableCell 
            item={item} 
            col={col} 
            value={item[col.dataIndex] ?? item}
            key={index}
        />
    )

    const onClick = () => {
        expandable && setIsVisible(!isVisible)
    }
    // console.log(expandKey)
    return(
        <>
            <div className="table-row" style={{...style, backgroundColor: `${item.isDeployed || needDeploy ? "" : "#ffc0c0"}`}} onClick={onClick}>
                {cells}     
            </div> 
             <div className="table-inner">
                {isVisible ? <Table items={item[expandKey]} columns={expandColumns}/> : ''}
            </div>
        </>
        
    )
}