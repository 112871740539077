import './abi.css'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as abiActions from "../../actions/abiActions"
import CustomModal from '../../components/modal'
import {useEffect, useState} from 'react'
import Button from '../../components/button'
import './abi.css'
import Table from '../../components/table'
import {Form, Input, Tooltip, Select} from 'antd'

import JSONInput from 'react-json-ide';
import locale from "react-json-editor-ajrm/locale/en";
import * as extensionActions from "../../actions/extensionActions"

import {
  InfoCircleOutlined
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea'
const {Option} = Select

const getEmptyAbi = () => {
  return {
    contract: '',
    net: '',
    abi: ''
  }
}
function Abi(props) {
  const {abis} = props
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = Form.useForm();
  const {extensions } = props;

  const items = abis.map((item, i) => {
    return {...item, number: i + 1}
})



  const columns = [
    {
      width: '3%',
      render: record => <div>
          +
      </div>,
    },
    {   
        dataIndex: 'number',
        width: '17%',        
    },
    {
        dataIndex: 'contract',
        width: '80%',
        render: record => <div className="permission-name">
            {record.contract}
        </div>
    }
  ]

  const closeModal = () => {
    setModalVisible(false)
    form.setFieldsValue(getEmptyAbi())
  }

  const onFinish = values => {
    if(values?.abi?.error) return
    values.abi = values.abi.jsObject
    // console.log(values)
    props.abiActions.addAbi(values)
    closeModal()
  }

  useEffect(() => {
    props.abiActions.getAbis()
    props.extensionActions.getExtensions() 
  }, [])

  const projectOptions = extensions.map((i,key)=>(
    <Option key={key} value={i.id}>{i.name}</Option>
  ))

  return(
      <div className='abi'>
          <div className='abi-head'>
              <div className='abi-title'>
                  ABI
              </div>
              <Button type={'primary'} style={{width: '20%'}} position={'end'} onClick={() => setModalVisible(true)}>Create new ABI</Button>         
          </div>
          <Table
            columns={columns}
            items={items}
            rowStyle={{cursor: 'pointer'}}
            expandable
            expandKey={'abi'}
            expandColumns={[
              {
                dataIndex: 'name',
                width: '25%',
                render: record => <div className="permission-name">
                    {record.name}
                </div>,
              },
              {
                dataIndex: 'inputs',
                width: '30%',
                render: record => <div>
                    ({record.inputs.map(item => `${item.type}`).join(', ')})
                </div>,       
              }
            ]}
          />
           <CustomModal
                isVisible={modalVisible}
                onCancel={closeModal}
                title={'Add new abi'}
                width="1000px"
            >
                <Form
                    style={{padding: '0 150px'}}
                    name="add-validator"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                    flex: 1,
                    }}  
                    form={form}
                    onFinish={onFinish}
                >
                    <div className='form-group-label-group'>
                        <div className="extension-label">Contract* </div> 
                        <Form.Item name={"contract"} rules={[{ required: true }]}>
                        <Input
                            placeholder="Contract address (0x...)t"
                            suffix={
                            <Tooltip title="Contract">
                                <InfoCircleOutlined />
                            </Tooltip>
                            }
                        />
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Network* </div> 
                        <Form.Item name={"net"}  rules={[{ required: true }]}>
                            <Select
                                showSearch={true}
                                placeholder="Select a network"
                                optionFilterProp="children"
                                // onSearch={onSearch}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                            >
                            {['bsc', 'eth', 'polygon', 'arbitrum', 'avalanche', 'optimism'].map((item, i) => <Option value={item} key={i}>{item}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Project* </div> 
                        <Form.Item name={"project_id"}  rules={[{ required: true }]}>
                          <Select
                            showSearch={false}
                            placeholder="Select a project"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                          >
                          {projectOptions}
                          </Select>
                        </Form.Item>
                    </div>
                    {/* <div className='form-group-label-group'>
                    
                        <Form.Item name={"abi"}>
                        <TextArea
                           rows={15}  
                           placeholder="Input abi"
                        />
                        </Form.Item>
                    </div> */}
                  
                    <div className='form-group-label-group'>
                      <div className="extension-label-before">ABI* </div> 
                        <Form.Item name={"abi"}>
                          
                        <JSONInput
                          id={1}
                          placeholder={[
                            {
                              "anonymous": false,
                              "inputs": [
                                {
                                  "indexed": true,
                                  "internalType": "uint8",
                                  "name": "channelId",
                                  "type": "uint8"
                                },
                                {
                                  "indexed": true,
                                  "internalType": "address",
                                  "name": "contractAddr",
                                  "type": "address"
                                }
                              ],
                              "name": "testAbi",
                              "type": "event"
                            }]}
                          theme="light_mitsuketa_tribute"
                          locale={locale}
                          height="550px"
                          width="100%"
                          onKeyPressUpdate="true"
                          waitAfterKeyPress='100'
                        />
                        </Form.Item>
                    </div>
                   
                    <Form.Item>
                        <Button type="primary" position={'center'} style={{textTransform: 'none', width: '30%'}}>
                            Save
                            {/* {props.isLoading ? <Spin /> : ""} */}
                        </Button>
                    </Form.Item>
                </Form>
            </CustomModal>
      </div>
  )
}

const mapStateToProps = state => ({
    isLoading: state.abi.isLoading,
    abis: state.abi.abis,
    extensions: state.extension.extensions
  })
  
  
  const mapDispatchToProps = dispatch => ({
    abiActions: bindActionCreators(abiActions, dispatch),
    extensionActions: bindActionCreators(extensionActions, dispatch)
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Abi)