import { combineReducers } from "redux";
import auth from "./authReducer";
import extension from "./extensionReducer";
import errorReducer from "./errorReducer";
import project from "./projectReducer";
import build from "./buildReducer";
import permission from './permissionReducer'
import abi from "./abiReducer";
import protobuf from "./protobufReducer";

export default combineReducers({
    auth, extension, errorReducer, build, project,
    permission, abi, protobuf
})