import './labelText.css'

function LabelText({label, text, icon, url, style}) {
    return(
        <div className="card-text" style={style}>
            <p className="card-label">{label}</p>
            {url ? 
                <a href={url} target="_blank" rel="noreferrer">{url}</a>:       
                <div className="card-label--content">{text} {icon ? <img src={icon} alt="pipeline"/> : ''}</div>
            }
        </div>
    )
}

export default LabelText