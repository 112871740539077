import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as extensionActions from "../../actions/extensionActions"
import './extensionDitail.css'
import {
    ArrowLeftOutlined,
  } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import {  Spin, Typography, Button} from 'antd';
import BreadcrumbCommon from '../../components/breadcrumb';
import CommonLayout from '../../components/commonLayout';
import BigCard from '../../components/bigCard';
import LabelText from '../../components/labelText';
import BigCardHeader from '../../components/bigCard/header';
const { Paragraph } = Typography;
// import {Outlet, Link} from 'react-router-dom';
// const { Header, Sider, Content } = Layout;


function ExtensionDitail(props) {
    const navigate = useNavigate()
    const {extension, isLoading} = props
    const { id } = useParams();
    const empty = 'No information, please fill.'
    useEffect(()=>{
         props.extensionActions.getExtension(id) 
    }, [id, props.extensionActions])

  

    const [editaedFields, setEditaedFields] = useState('');

    const editFields = (text, key) => {
        setEditaedFields({
            ...editaedFields,
            [key]: text
        })
    }
 
    return(
        <div className="extension">
        {isLoading ? (<Spin tip="Loading..." size='large'/>) :
        ( <CommonLayout>
            <BreadcrumbCommon projectName={extension.projects?.name || empty} projectId={extension?.projects?.id} extensionId={extension.id} extensionName={editaedFields.name || extension.name || empty}/>
                <BigCard>
                <div className='extension-upper'>
                  <BigCardHeader name={editaedFields.name || extension.name || empty} logo={extension.logo}/>
                    <div className="extension-upper--right">
                        <div className="extension-background_color">
                            <span>Color card</span>
                            <div className="extension-bg-color">{extension.background_color || empty}</div>
                        </div>
                    </div>
                </div>
                <LabelText label={'Description'} text={editaedFields.description || extension.description || empty}/>
                <LabelText label={'Card description'} text={editaedFields.card_description || extension.card_description || empty}/>
                {/* <LabelText label={'What we can'} text={editaedFields.what_you_can || extension.what_you_can || empty}/> */}
                <LabelText label={'Video'} text={extension.url_video || empty}/>
                </BigCard>
            </CommonLayout>)}
        </div>
       
    )
}

const mapStateToProps = state => ({
    isLoading: state.extension.isLoading,
    extension: state.extension.extension
})


const mapDispatchToProps = dispatch => ({
    extensionActions: bindActionCreators(extensionActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionDitail)