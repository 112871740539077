import * as types from './types'

export function getPermissions() {
    return {type: types.GET_PERMISSIONS}
}

export function getPermissionBuilds() {
    return {type: types.GET_PERMISSION_BUILDS}
}

export function addPermission(data) {
    return {type: types.ADD_PERMISSION, data}
}

export function updatePermission(data) {
    return {type: types.UPDATE_PERMISSION, data}
}
export function deletePermission(data) {
    return {type: types.DELETE_PERMISSION, data}
}