import { PERMISSION_TYPES, PERMISSION_TYPE_COLORS } from "../../common/constants";
import Button from "../../components/button";
import Table from "../../components/table";
import './permission.css'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as permissionActions from "../../actions/permissionActions"
import * as projectActions from "../../actions/projectActions"
import {useEffect, useState} from 'react'
import Empty from "../../components/empty";
import CustomModal from "../../components/modal";
import {Form, Input, Tooltip, Select} from 'antd'
import {
    InfoCircleOutlined
  } from '@ant-design/icons';
import Warning from "../../components/modal/warning";
const {Option} = Select;

const getEmptyPermission = () => {
    return {
      readable_name: '',
      type: undefined,
      method: '',
      net: undefined,
      address: ''
    }
  }

function Permission(props) {
    const {permissions, nets} = props
    const [modalVisible, setModalVisible] = useState(false)
    const [warningModalVisible, setWarningModalVisible] = useState(false)
    const [editId, setEditId] = useState(0)
    const [deleteId, setDeleteId] = useState(0)
    const [form] = Form.useForm();

    useEffect(() => {
        props.permissionActions.getPermissions()
        props.projectActions.getNets()
    }, [])

    const items = permissions.map((item, i) => {
        return {...item, number: i + 1}
    })

    const columns = [
        {
            width: '3%',
            render: record => <div>
                +
            </div>,
          },
        {   
            dataIndex: 'number',
            width: '5%',        
        },
        {
            dataIndex: 'readable_name',
            width: '10%',
            render: record => <div className="permission-name">
                {record.readable_name}
            </div>
        },
        {
            dataIndex: 'parameters',
            width: '62%',
            render: record => <div className="permission-parameter">{JSON.stringify(record.parameters)}</div>
        },
        {
            dataIndex: 'type',
            width: '10%',
            justify: 'flex-end',
            render: record => <div style={{color: PERMISSION_TYPE_COLORS[record.type]}}>
                {record.type}
            </div>
        },
        {
            width: '10%',
            justify: 'flex-end',
            render: record => <div className="permission-actions">
                             <div className='edit' onClick={() => openEditModal(record)}></div> 
                             <div className='delete' onClick={() => deletePermission(record.id)}></div> 
                          </div>
        ,
        }
    ]

    const closeModal = () => {
        setModalVisible(false)
        setEditId(0)
        form.setFieldsValue(getEmptyPermission())
    }

    const deletePermission = id => {
        setDeleteId(id)
        setWarningModalVisible(true)
    }

    const openEditModal = record => { 
        if (record.id) {
            // console.log(record)
            setEditId(record.id)
            form.setFieldsValue({...record, ...record.parameters})
        }
        setModalVisible(true)
    }

    const onFinish = values => {
        const parameters = {
            address: values.address,
            net: values.net,
            method: values.method
        }
        const data = {readable_name: values.readable_name, type: values.type, parameters}
        if(editId) {
            props.permissionActions.updatePermission({...data, id: editId})
        } else props.permissionActions.addPermission(data)
        closeModal()
    }

    return(
        <div className="permissions">
            <div className="permission-head">
                <div className="permission-title">
                    Permissions
                </div>
                <Button type={'primary'} style={{width: '20%'}} position={'end'} onClick={() => setModalVisible(true)}>Add permission</Button>
            </div>
            <div className="permission-body">
              {
                permissions.length ?  
                <Table
                    columns={columns}
                    items={items}
                    expandKey={'extensions_permissions'}
                    rowStyle={{cursor: 'pointer'}}
                    expandable
                    expandColumns={[
                        {
                            width: '10%',
                            render: record => <div className="permission-name">
                                {record.extensions_builds.version}
                            </div>
                        },
                        {
                            width: '50%',
                            render: record => <div className="permission-name">
                                {record.extensions_builds.projects.name}
                            </div>
                        },
                        {
                            width: '40%',
                            render: record => record.extensions_builds.url
                      
                        },
                    ]}
                /> :
                <Empty type={'permission'}/>
              }
            </div>
            <CustomModal
                isVisible={modalVisible}
                onCancel={closeModal}
                title={editId ? 'Edit permission' : 'Add new permisson' }
            >
                <Form
                    style={{padding: '0 150px'}}
                    name="add-validator"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                    flex: 1,
                    }}  
                    form={form}
                    onFinish={onFinish}
                >
                    <div className='form-group-label-group'>
                        <div className="extension-label">Readble name (Permission alias)* </div> 
                        <Form.Item name={"readable_name"} rules={[{ required: true }]}>
                        <Input
                            placeholder="i.e Swap"
                            suffix={
                            <Tooltip title="Permission name for easier developer experience">
                                <InfoCircleOutlined />
                            </Tooltip>
                            }
                        />
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Method* </div> 
                        <Form.Item  name={"method"} rules={[{ required: true }]}>
                        <Input
                            placeholder="Amino format message type for Cosmos or contract method name"
                            suffix={
                            <Tooltip title="i.e. osmosis/superfluid-delegate for Cosmos SDK or Approve for EVM contract method">
                                <InfoCircleOutlined />
                            </Tooltip>
                            }
                        />
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Address (Contract address (EVM)) </div> 
                        <Form.Item  name={"address"}>
                        <Input
                            placeholder="0x..."
                            suffix={
                            <Tooltip title="EVM networks only">
                                <InfoCircleOutlined />
                            </Tooltip>
                            }
                        />
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Network* </div> 
                        <Form.Item name={"net"} rules={[{ required: true  }]}>
                            <Select
                                showSearch
                                placeholder="Select a network"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                        {nets.map((item, i) => <Option key={i} value={item.net}>{item.net}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Type* </div> 
                        <Form.Item name={"type"}  rules={[{ required: true }]}>
                            <Select
                                showSearch={true}
                                placeholder="Select a type"
                                optionFilterProp="children"
                                // onSearch={onSearch}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                            >
                            {PERMISSION_TYPES.map((item, i) => <Option selected={true} value={item} key={i}>{item}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" position={'center'} style={{textTransform: 'none', width: '30%'}}>
                            Save
                            {/* {props.isLoading ? <Spin /> : ""} */}
                        </Button>
                    </Form.Item>
                </Form>
            </CustomModal>
            <CustomModal
                title=""
                isVisible={warningModalVisible}
                onCancel={() => setWarningModalVisible(false)}
                color="#FA3B33"
                hideClose
            >
                <Warning
                    title={'Do you want to delete permission?'}
                    onFinish={() => props.permissionActions.deletePermission(deleteId)}
                    onCancel={() => setWarningModalVisible(false)}
                />
            </CustomModal>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.permission.isLoading,
    permissions: state.permission.permissions,
    nets: state.project.nets
  })
  
  
  const mapDispatchToProps = dispatch => ({
    permissionActions: bindActionCreators(permissionActions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch)
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Permission)