import Button from '../../components/button'
import CardSelect from '../../components/card-select'
import './project-network.css'
import {ReactComponent as Plus} from '../../assets/plus.svg';
import {ReactComponent as Close} from '../../assets/close.svg';
import { useState } from 'react';
import CustomModal from '../../components/modal';
import Table from '../../components/table';
import Checkbox from "../../components/checkbox";
import { useEffect } from 'react';

export default function ProjectNetwork({formats, projectId, projectActions, allFormats, style, icon, hasImg, bordered}) {
    const [modalVisible, setModalVisible] = useState(false)
    const [selectedFormats, setselectedFormats] = useState([])

    const netItems = formats.map(format => 
        <CardSelect 
            value={format.name} 
            hasImg={hasImg}
            img={icon ? icon : ''}
            width={style?.cardWidth ? style.cardWidth : 'calc(12.5% - 10px)'}
            hasAction
            key={format.id}
            style={{ backgroundColor: '#F1EEFF' }}
            onTap={() => 
            projectActions.addFormatsToProject(
                {
                    id: projectId, 
                    formats: formats.filter(item => item.id !== format.id)
                })}
        />)

    useEffect(() => {
        // console.log(nets)
        setselectedFormats(formats.map(item => {
            return {
                id: item.id,
                name: item.name,
                isDeployed: item.isDeployed
            }
        }))
    }, [formats])

    // console.log(selectedFormats)
    
    const columns = [
        {   
            width: '70%',
            dataIndex: 'net',
            render: record => <div className="network-name">
                    {record.name}
            </div>
        },
        {
            width: '30%',
            justify: 'flex-end',
            render: record => <Checkbox style={{backgroundColor: '#C3CEEB'}} value={record} onChange={onChange} checked={selectedFormats.some(net => net.id === record.id)}/>
        },
    ]

    // console.log(allNets)

    const onChange = (checked, value) => {
        if (checked) {
            setselectedFormats([...selectedFormats, value])
        } else {
            setselectedFormats(selectedFormats.filter(p => p.id !== value.id))
        }
    }

    const closeModal = () => {
        setselectedFormats([])
        setModalVisible(false)
    }

    const onSave = (e) => {
        e.preventDefault()
        projectActions.addFormatsToProject({id: projectId, formats: selectedFormats})
        closeModal()
    }

    const onUnselect = () => {
        setselectedFormats([])
    }

    const onSelect = () => {
        setselectedFormats(allFormats)
    }

    return(
        <>
            <div className='project-nets' style={{borderBottom: bordered && '1px dashed #8B9BC7', paddingBottom: '10px'}}>
                {netItems}
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        setModalVisible(true)
                    }}
                    position={'start'} 
                    noWrapper
                    style={{
                            width: '100%',
                            borderRadius: '4px', 
                            backgroundColor: '#E4ECFF',
                            border: '1px solid #1A53F0',
                            color: '#1A53F0',
                            textTransform: 'none',
                            padding: '6px 8px',
                            fontFamily: 'Panton Medium',
                            fontSize: '17px'}}
                    >
                    <div className="add-network">
                        <Plus fill='#1A53F0' stroke="#1A53F0" />
                    </div>
                    Add format
                </Button>
            </div>
            <CustomModal
                isVisible={modalVisible}
                onCancel={closeModal}
                title={'Add format'}
            >
                <div className='network-actions'>
                    <div className='select-all' onClick={onSelect}>
                       Select all
                    </div>
                    <div className='unselect' onClick={onUnselect}>
                        <span>Unselect all</span>
                        <div className="unselect-icon">
                            <Close fill='#fff' stroke="#fff" />
                        </div>
                    </div>
                </div>
               
                <Table
                    columns={columns}
                    items={allFormats}
                    style={{maxHeight: '300px'}}
                >
                </Table>
                <Button 
                    type={'primary'} 
                    position={'center'} 
                    style={{width: '35%'}}
                    onClick={onSave}
                >
                    Save
                </Button>
            </CustomModal>
        </>
            
    )
}