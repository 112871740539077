import {all, put, takeLatest} from "redux-saga/effects"
import * as types from "../actions/types"
import axios from 'axios'

import { ENDPOINT } from "../config"

function* getExtensions() {
    try {
        const extensionResponse = yield axios.get(`${ENDPOINT}/dev-center/extensions`).then(res => res.data)
        yield put({type: types.SET_EXTENSIONS, payload: extensionResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getExtensionsByProject(action) {
    const {data} = action
    try {
        const extensionResponse = yield axios.get(`${ENDPOINT}/dev-center/extensions/project/${data}`).then(res => res.data)
        yield put({type: types.SET_EXTENSIONS_BY_PROJECT, payload: extensionResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getExtension(action) {
    const {data} = action
    try {
        const extensionResponse = yield axios.get(`${ENDPOINT}/dev-center/extensions/` + data).then(res => res.data)
        yield put({type: types.SET_EXTENSION, payload: extensionResponse.data})
    } catch(error) {
        yield put({type: types.ERROR, error: error.response.data?.data  || error.message})
    }
}

function* addExtension(action) {
    const {data, navigate} = action
    try {
        const extensionResponse = yield axios.post(`${ENDPOINT}/dev-center/extensions/new`, data, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then(res => res.data)
        yield put({type: types.ADDED_EXTENSION, payload: extensionResponse.data})
        
        navigate('/dashboard/apps')
    } catch(error) {
        yield put({type: types.ERROR, error: error.response.data?.data  || error.message})
    }
}

export function* extensionSaga() {
    yield all([
        yield takeLatest(types.GET_EXTENSIONS, getExtensions),
        yield takeLatest(types.GET_EXTENSION, getExtension),
        yield takeLatest(types.ADD_EXTENSION, addExtension),
        yield takeLatest(types.GET_EXTENSIONS_BY_PROJECT, getExtensionsByProject),
    ])
}