import {connect} from 'react-redux'
import './builds.css'
import React from 'react';
import {  Spin} from 'antd';
import BuildCard from '../../components/buildCard';
import Empty from '../../components/empty';

function BuildList(props) {
    const {builds, isLoading} = props
    // const { id } = useParams( );

    const builds_component = builds.map((i, index) => (
        <BuildCard style= {{width: '100%'}} key={index} build={i}></BuildCard>
    ))


    return(
        <div className="builds-container">
            {isLoading ? (<Spin tip="Loading..." size='large'/>) :
               builds.length ? builds_component :  (<Empty type={"build"}/>)}
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.extension.isLoading,
})


const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(BuildList)