import './commonLayout.css'

function CommonLayout(props) {
    return(
        <div className="common-layout">
            {props.children}
        </div>
    )
}

export default CommonLayout