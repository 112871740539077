import './buildCard.css';
import React, { useState }  from 'react';
import {Alert} from 'antd';

import {Link} from 'react-router-dom'
import moment from 'moment'
import CustomModal from '../modal';
import { LogViewer, LogViewerSearch} from '@patternfly/react-log-viewer';
import { Toolbar, ToolbarContent, ToolbarItem, Button, Checkbox } from '@patternfly/react-core';
import { STATUS} from '../../common/constants'

function capitalizeFirstLetter(string) {
    if(typeof string === 'string') 
    return string[0].toUpperCase() + string.slice(1);
}
function BuildCard(props) {
    let {id, status, url, version, date_created, icon, pipeline_status, log} = props.build
    const [modalVisible, setModalVisible] = useState(false);

    const closeModal = () => {
        setModalVisible(false)
    }

    const clickToStatus = () => {
        if(pipeline_status !== "failed") return
        setModalVisible(true)
    }

    return(
        <div className="buildCard">
            <div className="buildCard-head">
                <div className="buildCard-title">
                    Build <span>({id})</span>
                </div>
                <div className="buildCard-status">    
                    <p>Version: <span>{version}</span></p>
                    <Alert message={status} 
                    type={STATUS[status]} />
                </div>
            </div>
            <div className="buildCard-body">
                <div className="buildCard-url">
                    Upload date: 
                    <p>{moment(date_created).format('MMMM Do YYYY, h:mm a')}</p>
                </div>
                <div className="buildCard-url">
                    URL: 
                    <a target={'_blank'} href={url} rel="noreferrer">{url}</a>
                </div>
                <div className="buildCard-url" onClick={clickToStatus} style={{ cursor: pipeline_status === 'failed' ? "pointer" : ''}}>
                    Pipeline status: 
                    <p>{capitalizeFirstLetter(pipeline_status)}</p><img src={icon} alt="pipeline"/>
                </div>
                <div className="buildCard-more">
                    <Link className='buildCard-card-options-link' to={`/projects/build/${id}`}>
                        <p>More</p>
                      <img className="arrow" alt='arrow' src='/img/icons/more.svg'/> 
                      
                    </Link>
                </div>
            </div>
            <CustomModal
                isVisible={modalVisible}
                onCancel={closeModal}
                title={"Build logs"}
                width="1500px"
                height="600px"
            >
                <LogViewer
                    theme='dark'
                    data={log}
                    overScanCount={99}
                    // height={600}
                    // width={1500}
                    isTextWrapped={false}
                    toolbar={
                        <Toolbar>
                          <ToolbarContent>
                            
                            <ToolbarItem>
                              <LogViewerSearch placeholder="Search" />
                            </ToolbarItem>
                        
                          </ToolbarContent>
                        </Toolbar>
                      }
                    />
                </CustomModal>
        </div>
    )
}

export default BuildCard