import {Button, Form, Input} from 'antd'
import './comment.css'
const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
      <Form.Item>
        <TextArea rows={5} onChange={onChange} value={value}  placeholder={'Write a review'}/>
      </Form.Item>
    </>
  );

function Comment(props) {
    const {handleChange, handleSubmit, submitting, value} = props;
    return(
        <div>
            <Editor
              onChange={handleChange}
              onSubmit={handleSubmit}
              submitting={submitting}
              value={value}
            />
        </div>
    )
}

export default Comment