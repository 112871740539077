import './extensionCard.css'
import React  from 'react';


import {Link} from 'react-router-dom'
// import { Alert } from 'antd';

function ExtensionCard(props) {

    const {logo, short_description, description, name, background_color, id} = props.extension
    return(
        <div className="extension-card" style={{ borderLeft: `8px solid ${background_color}`}}>
            <div className="extension-card-head">
                <div className="extension-card-head-logo"><img alt='logo' src={logo} /></div>
                <div className="extension-card-titles">
                    <div className="extension-card-head-name">{name} <span>({id})</span></div>
                    <div className="extension-card-head-short-description">{short_description}</div>
                </div>
            </div>
            <div className="extension-card-description">{description}</div>
            <div className="extension-card-options">
                {/* <div className="extension-card-options-status">
                    <p>App status:</p>
                    <Alert message={app_status} type="info" />
                </div>
                <div className="extension-card-options-status">
                    <p>Latest build status:</p>
                    <Alert message={build_status} type={build_status === 'confirmed'  ? 'success' : build_status === 'review' ? 'warning' : 'error'} showIcon />
                </div> */}
                <Link className='extension-card-options-link' to={`/apps/${id}`}>
                    <p>More</p>                      
                    <img className="arrow" alt='arrow' src='/img/icons/more.svg'/>  
                </Link>
            </div>
        </div>
    )
}

export default ExtensionCard