import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as projectActions from "../../actions/projectActions"
import './projects.css'
import React, { useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CustomModal from '../../components/modal'
import {Form, Input, Tooltip} from 'antd'
import Button from '../../components/button';
import Warning from '../../components/modal/warning';
import {ReactComponent as Plus} from '../../assets/plus.svg';

import {
  InfoCircleOutlined
} from '@ant-design/icons';

const getEmptyProject = () => {
  return {
    name: '',
    project_slug: '',
    git_url: ''
  }
}

function Projects(props) {
    const {projects} = props;
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [editedId, setEditedId] = useState(0)
    const [warningModalVisible, setWarningModalVisible] = useState(false)
    const [deleteId, setDeleteId] = useState(0)
    // const [project, setProject] = useState(getEmptyProject())
    const [form] = Form.useForm();
    const navigate = useNavigate()
    useEffect(() => {
     
      props.projectActions.getProjects()
    },[props.projectActions])


    const projectsComponent = projects?.map((project, index) => {
      // let developer = null
      // if(project.developers_projects.length){
      //   developer = project.developers_projects[0].developers.full_name;
      // }
      return (<div onClick={() => {navigate(project.id)}} key={index} className="project-item"> 
        <div className='project-item-info'>
          <div className='project-item-info-label'>Project name</div>
          <div className='project-item-info-name'>{project.name}</div>
        </div> 
        {/* {(developer ? 
        <div className='project-item-info-user'>
          <div className='project-item-info-label'>Developer</div>
          <div className='project-item-info-name'>{developer}</div>
        </div>  : '')} */}
        <div className="project-item-actions">
          <div className='edit' onClick={(event) => {event.stopPropagation(); openEditModal(project)}}></div> 
          <div className='delete' onClick={(event) => deleteProject(event, project.id)}></div> 
        </div>
      </div>) 
    })

    const deleteProject = (event, id) => {
      event.stopPropagation(); 
      setDeleteId(id)
      setWarningModalVisible(true)
     
    }
    

    const closeModal = () => {
      setIsModalVisible(false)
      setEditedId(0)
      form.setFieldsValue(getEmptyProject())
    }

    const openModal = () => {
      setIsModalVisible(true)
    }

    const openEditModal = (project) => {
      setEditedId(project.id)
      form.setFieldsValue({...project})
      openModal()
    }
    

    const onFinish = values => {
      // console.log(editedId)
      if(editedId){
        props.projectActions.update({...values, project_id: editedId})
      } else props.projectActions.create(values);
      closeModal()
    }

    return(
        <div className="project-container">
          {projectsComponent}  
          <div className="project-item create-project" onClick={openModal}> 
            <div>
              <Plus fill='#1A53F0' stroke="#1A53F0" />
            </div>
            <p>Add new project</p>
          </div>  
          <CustomModal 
            isVisible={isModalVisible} 
            onCancel={closeModal}
            color={'#00A3FF'}
            title={editedId ? `Edit project` : 'Add new project'}
          >
            <Form
            style={{padding: '0 150px'}}
            name="add-validator"
            labelAlign="left"
            labelWrap
            wrapperCol={{
              flex: 1,
            }}  
            form={form}
            onFinish={onFinish}
            >
              <div className='form-group-label-group'>
                <div className="extension-label">Name * </div> 
                <Form.Item  className='modal-input' name={"name"} rules={[{ required: true }]}>
                  <Input
                    placeholder="My app"
                   
                    suffix={
                      <Tooltip title="Internal name for your project (won't be visible for users)">
                        <InfoCircleOutlined />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </div>
              <div className='form-group-label-group'>
                <div className="extension-label">Project slug * </div> 
                <Form.Item name={"project_slug"} rules={[{ 
                    required: true,
                    pattern: new RegExp(/^(?![0-9]+$)(?!.*-$)(?!-)[a-zA-Z0-9-]{1,63}$/g),
                    message: "Wrong format!"
                    }]}>
                  <Input
                    placeholder="appname"
                    suffix={
                      <Tooltip title="Customized part of the project's URL">
                        <InfoCircleOutlined />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </div>
              <div className='form-group-label-group'>
                <div className="extension-label">Git url * </div> 
                <Form.Item name={"git_url"} rules={[{ required: true }]}>
                  <Input
                    placeholder="https://github.com/citadeldao/ca-base-app"
                    suffix={
                      <Tooltip title="Git url">
                        <InfoCircleOutlined />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item>
              <Button type="primary" position={'center'} style={{textTransform: 'none', width: '30%'}}>
                Save
                {/* {props.isLoading ? <Spin /> : ""} */}
              </Button>
              </Form.Item>
              </Form>
           </CustomModal>
           <CustomModal
                title=""
                isVisible={warningModalVisible}
                onCancel={() => setWarningModalVisible(false)}
                color="#FA3B33"
                hideClose
            >
                <Warning
                    title={'Do you want delete project?'}
                    onFinish={() =>  props.projectActions.deleteProject(deleteId)}
                    onCancel={() => setWarningModalVisible(false)}
                />
            </CustomModal>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.extension.isLoading,
    projects: state.project.projects,
})


const mapDispatchToProps = dispatch => ({
    projectActions: bindActionCreators(projectActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)