import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as authActions from "../../actions/authActions"
import './dashboard.css'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    LogoutOutlined,
    InfoCircleOutlined
  } from '@ant-design/icons';
import { Layout, Menu, Dropdown, Tooltip } from 'antd';
import React, { useState , useEffect } from 'react';
import {Outlet, Link} from 'react-router-dom';
import Button from '../../components/button';
import { copyToClipboard } from '../../common/utils';
import TooltipCustom from '../../components/tooltip';
import { useNavigate } from 'react-router-dom';
const { Header, Sider, Content } = Layout;



function Dashboard(props) {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate()
    const {user:{full_name}, token, user} = props
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const urls = ['projects', 'create-build', 'permissions', 'abi', 'protobuf', 'formats']
    
   
    useEffect(()=>{
      props.authActions.getToken()
 }, [props.authActions])


    const logout = () => {
        props.authActions.logout(navigate)
    }

    const accountMenu = (
      <Menu
        items={[
          {
            label: <div onClick={logout}>Logout</div>,
            key: '1',
            icon: <LogoutOutlined/>
          },
        ]}
      />
    );

    const adminItems = []

    if(user.role === 'admin'){
      adminItems.push({
        key: '5',
        icon: <object type="image/svg+xml" data="/img/icons/protobuf.svg" className="icon icon-bell">
                Format
              </object>,
        label: <Link to="formats">Formats</Link>,
      })
    }
    
    return(
        <div>
           <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
              <div className="dashboard-logo" ><img className='dashboard-logo-img'  alt= 'logo' src={collapsed ? '/img/icons/small-logo.svg':'/img/icons/logo.svg'}/> </div>
              <Menu
                theme="dark"
                mode="inline"
                selectedKeys={urls.findIndex(item => window.location.href.includes(item)).toString()}
                items={[
                  {
                    key: '0',
                    icon: <object type="image/svg+xml" data="/img/icons/project.svg" className="icon icon-bell">
                            Projects
                          </object>,
                    label: <Link to="projects">Projects</Link>,
                  },
                  {
                    key: '1',
                    icon: <object type="image/svg+xml" data="/img/icons/create_build.svg" className="icon icon-bell">
                            Create build
                          </object>,
                    label: <Link to="create-build">Create build</Link>,
                  },
                  {
                    key: '2',
                    icon: <object type="image/svg+xml" data="/img/icons/permission.svg" className="icon icon-bell">
                            Permissions
                          </object>,
                    label: <Link to="permissions">Permissions</Link>,
                  },
                  {
                    key: '3',
                    icon: <object type="image/svg+xml" data="/img/icons/abi.svg" className="icon icon-bell">
                            Abi
                          </object>,
                    label: <Link to="abi">ABI</Link>,
                  },
                  // {
                  //   key: '4',
                  //   icon: <object type="image/svg+xml" data="/img/icons/protobuf.svg" className="icon icon-bell">
                  //           Protobuf
                  //         </object>,
                  //   label: <Link to="protobuf">Protobuf</Link>,
                  // },
                  ...adminItems
                ]}        
                />
            </Sider>
            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{
                  padding: '0 32px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
               {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed),
                })}
                <Button
                  onClick={() => {
                    setTooltipVisible(true)
                    copyToClipboard(token)
                    setTimeout(() => {
                      setTooltipVisible(false)
                    }, 2500)            
                    }}
                  type="primary" 
                  style={{
                    borderRadius: '6px', 
                    textTransform: 'none', 
                    fontFamily: 'Panton medium',
                    height: '40px',
                    marginLeft: '20px',
                    marginRight: '0px'
                    }}
                >
                  Dev code
                  <TooltipCustom visible={tooltipVisible}text={'Copied to clipboard!'}>
                  </TooltipCustom>
                  <Tooltip  title="You can connect developer account for testing via Extension settings in Citadel.one app">
                      <InfoCircleOutlined style={{marginLeft: '10px'}}/>
                  </Tooltip>
                </Button>
               
                <Dropdown overlay={accountMenu} overlayStyle={{width: '150px', minWidth: '120px',}} placement={'topRight'}>
                  <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer'}}>
                    <UserOutlined style={{marginRight: '10px'}}/>
                    {full_name}
                  </div>
                </Dropdown>          
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  margin: '24px 16px',
                  padding: 30,
                  minHeight: 280,
                }}
              >
                <Outlet/>
              </Content>
            </Layout>
    </Layout>
        </div>
    )
}

const mapStateToProps = state => ({
    error: state.auth.error,
    isLoading: state.auth.isLoading,
    isLoadingToken: state.auth.isLoadingToken,
    user: state.auth.user,
    token: state.auth.token
})


const mapDispatchToProps = dispatch => ({
    authActions: bindActionCreators(authActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)