import {all, put, takeLatest} from "redux-saga/effects"
import * as types from "../actions/types"
import axios from 'axios'

import { ENDPOINT } from "../config"

function* getProtobufs(action) {
    try {
        const permissions = yield axios.get(`${ENDPOINT}/dev-center/protobuf/`).then(res => res.data)
        yield put({type: types.SET_PROTOBUFS, payload: permissions.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* addProtobuf(action) {
    const {data} = action
    try {
        const added = yield axios.post(`${ENDPOINT}/dev-center/protobuf/`, data).then(res => res.data)
        yield put({type: types.PROTOBUF_ADDED, payload: added.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

export function* protobufSaga() {
    yield all([
        yield takeLatest(types.GET_PROTOBUFS, getProtobufs),
        yield takeLatest(types.ADD_PROTOBUF, addProtobuf)
    ])
}