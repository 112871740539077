import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as extensionActions from "../../actions/extensionActions"
import './createApp.css'
import {
    InboxOutlined,
    InfoCircleOutlined,
  } from '@ant-design/icons';
import { Form, Tooltip , Input, Button, Upload} from 'antd';
import React, { useEffect, useState } from 'react';
// import {Outlet, Link} from 'react-router-dom';
import { ChromePicker } from 'react-color';
import { useNavigate } from 'react-router';

const { TextArea } = Input;
const { Dragger } = Upload;

function CreateApp(props) {
    const navigate = useNavigate()

    const { isLoading } = props;

    const onFinish = (values) => {
      props.extensionActions.addExtension({
        ...values, 
        is_prod: specificFeailds.is_prod,
        is_dev: specificFeailds.is_dev,
        logo: values.logo.file, 
        background: values.background?.file || '',
        background_color: values.background_color?.hex || '#fff'
      }, navigate)
    };    

    const [specificFeailds, setSpecificFields] = useState({
      pickerValue : {},
      color:'',
      // is_prod: false,
      // is_dev: false
    })
    
    useEffect(() => {
        // console.log(isLoading)
    }, [isLoading])

    return(
        <div>
           <Form
          style={{padding: '0 150px'}}
          name="add-validator"
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}  
          onFinish={onFinish}
        >
           <div className="extension-label">Name * </div> 
            <Form.Item name={"name"}  rules={[{ required: true }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Name of extensions">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
          <div className="extension-label">Short description * </div> 
          <Form.Item name={"short_description"} rules={[{ required: true }]}>
            <Input
              suffix={
                <Tooltip title="Text / Short description">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <div className="extension-label">Card description </div> 
          <Form.Item name={"card_description"} >
            <Input
              suffix={
                <Tooltip title="Text / Card description">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <div className="extension-label">Description * </div> 
          <Form.Item name={"description"} rules={[{ required: true }]}>
              <TextArea allowClear style={{ height: 120 }}/>
          </Form.Item>
          <div className="extension-label">Logo *</div> 
          <Form.Item name={"logo"} rules={[{ required: true }]}>
            <Dragger beforeUpload={() => false } {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Form.Item>
          <div className="extension-label">Background</div> 
          <Form.Item name={"background"} >
            <Dragger  beforeUpload={() => false} {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Form.Item>
          <div className="extension-label">Background  color</div> 
          <Form.Item name={"background_color"}>
              <ChromePicker 
              color={specificFeailds.color}
                value={specificFeailds.pickerValue}
                onChange={(e) => setSpecificFields({...specificFeailds, color: e.hex})}
              />
          </Form.Item>
          {/* <Form.Item name={"url"} label="URL" rules={[{ required: true }]}>
            <Input
              
              suffix={
                <Tooltip title="Text / Url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"url_prod"} label="URL PROD" rules={[{ required: true }]}>
            <Input
              suffix={
                <Tooltip title="Text / Url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item> */}
          <div className="extension-label">Video  url</div> 
          <Form.Item name={"url_video"}>
            <Input
              suffix={
                <Tooltip title="Text / Video url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          {/* <Form.Item name={"is_prod"} label="Prodaction or develop?" >
            Prodaction: <Checkbox  onChange={(e) => setSpecificFields({...specificFeailds, is_prod: e.target.checked})}/>
            Develop: <Checkbox  onChange={(e) => setSpecificFields({...specificFeailds, is_dev: e.target.checked})}/>
          </Form.Item> */}
           <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Create
            </Button>
          </Form.Item>
        </Form>
        </div>
    )
}

const mapStateToProps = state => ({
  isLoading: state.extension.isLoading,
  extension: state.extension.extension
})


const mapDispatchToProps = dispatch => ({
  extensionActions: bindActionCreators(extensionActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateApp)