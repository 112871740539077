import './main-card.css'

function MainCard(props) {
    const {logo = 'img/icons/default.svg', name, shortDescription, cardDescription, color, readMore} = props
    return(
        <div className="main-card" style={{borderLeft: `16px solid ${color}`}}>
            <div className='card-overlay'>
                <img src={'img/icons/bg.svg'} alt="bg"/>
            </div>
                <div className='main-card--title'>
                    <div className='main-card--logo'>
                        <img alt={'logo'} src={logo}/>
                    </div>
                    <div className='main-card--content'>
                        <div className='main-card--name'>{name}</div>
                        <div className='main-card--short'>
                            {shortDescription}
                        </div>
                    </div>
                </div>
                <div className='main-card--description'>
                    {cardDescription}
                </div>
                <div className="main-card--footer" onClick={readMore}>
                    <span>read more</span>
                    <img src={'img/icons/arrow-right.svg'} alt="arrow"/>
                </div>
        </div>
    )
}

export default MainCard