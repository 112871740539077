import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as extensionActions from "../../actions/extensionActions"
import * as buildActions from "../../actions/buildActions"
import * as projectActions from "../../actions/projectActions"
import * as permissionActions from "../../actions/permissionActions"
import BuildList from '../../containers/buildList';
import Extensions from '../../containers/extensions';
import './project.css'
import React, { useEffect, useState} from 'react';

import {
  InfoCircleOutlined
} from '@ant-design/icons';
import { Collapse, Tooltip} from 'antd';
import { useParams } from 'react-router';
import ProjectPermission from '../project-permission';
import ProjectNetwork from '../project-network'
import ProjectFormats from '../project-formats'
import isEmpty from '../../helpers/isEmpty';
import Accordion from '../../components/accordion'
import TooltipCustom from '../../components/tooltip';
import Button from '../../components/button';

import { copyToClipboard } from '../../common/utils';

const { Panel } = Collapse;

function Project(props) {
    const {project_extensions, builds, project_permissions, permissions, project_nets, nets, project, formats, project_formats} = props
    const {id} = useParams()
    const { token } = props
    const [tooltipVisible, setTooltipVisible] = useState(false)
    useEffect(() => {
        props.extensionActions.getExtensionsByProject(id)
        props.buildActions.getBuilds(id) 
        props.projectActions.getProjectPermissions(id) 
        props.projectActions.getProjectNets(id) 
        props.projectActions.getProjectFormats(id) 
        props.permissionActions.getPermissions()
        props.projectActions.getNets()
        props.projectActions.getFormats()
        props.projectActions.getProject(id)
        props.projectActions.getToken(id)

   }, [id, props.buildActions, props.extensionActions, props.permissionActions, props.projectActions])

  
    return(
        <>
        <div className="project-info">
            <div className='project-info--name'>{project.name}
            
            </div>
            <div className='project-info--developer'>
                <div>
                    Developer: 
                </div>
                <div>
                    {!isEmpty(project) && project.developers_projects.length ? project?.developers_projects[0].developers.full_name : ''}
                </div> 
            </div>
        </div>
            <Accordion
                isInitiallyVisible={true}
                title={`Builds (${builds.length})`}
                headerStyle={{padding: '20px 15px', fontSize: '20px'}}
                mode={'colored'}
                theme={'light'}
                cornered
              >
                  <BuildList builds={builds}/>
            </Accordion>  
            <Accordion
                title={`Descriptions (${project_extensions[0]?.extensions.length})`}
                headerStyle={{padding: '20px 15px', fontSize: '20px'}}
                mode={'colored'}
                theme={'light'}
                cornered
              >
                  <Extensions project_extensions={project_extensions}/>
            </Accordion> 
            {/* <Accordion
                title={`Permissions (${project_permissions.length})`}
                headerStyle={{padding: '20px 15px', fontSize: '20px'}}
                mode={'colored'}
                theme={'light'}
                cornered
              >
                  <ProjectPermission projectId={id} projectActions={props.projectActions} allPermissions={permissions} permissions={project_permissions}/>
            </Accordion>  
            <Accordion
                title={`Networks (${project_nets.length})`}
                headerStyle={{padding: '20px 15px', fontSize: '20px'}}
                mode={'colored'}
                theme={'light'}
                cornered
              >
                <ProjectNetwork allNets={nets} projectActions={props.projectActions}  nets={project_nets} projectId={id}/>
            </Accordion>  
            <Accordion
                title={`Format identifiers (${project_formats.length})`}
                headerStyle={{padding: '20px 15px', fontSize: '20px'}}
                mode={'colored'}
                theme={'light'}
                cornered
              >
                <ProjectFormats allFormats={formats} projectActions={props.projectActions}  formats={project_formats} projectId={id}/>
            </Accordion>   */}
          
            <Button
              onClick={() => {
                setTooltipVisible(true)
                copyToClipboard(token)
                setTimeout(() => {
                  setTooltipVisible(false)
                }, 2500)            
                }}
              type="primary" 
              style={{
                borderRadius: '6px', 
                textTransform: 'none', 
                fontFamily: 'Panton medium',
                height: '20px',
                marginRight: '0px'
                }}
            >
                Token for testing
                <TooltipCustom visible={tooltipVisible} text={'Copied to clipboard!'}>
                </TooltipCustom>
                <Tooltip  title="You can add token for testing via Extension settings in Citadel.one app">
                      <InfoCircleOutlined style={{marginLeft: '10px'}}/>
                </Tooltip>
            </Button>
            
        </>
    )
}

const mapStateToProps = state => ({
    isLoading: state.extension.isLoading,
    extensions: state.extension.extensions,
    builds: state.build.builds,
    project_extensions: state.extension.project_extensions,
    project_permissions: state.project.project_permissions,
    permissions: state.permission.permissions,
    project_nets: state.project.project_nets,
    project_formats: state.project.project_formats,
    nets: state.project.nets,
    formats: state.project.formats,
    project: state.project.project,
    token: state.project.token
})


const mapDispatchToProps = dispatch => ({
    extensionActions: bindActionCreators(extensionActions, dispatch),
    buildActions: bindActionCreators(buildActions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch),
    permissionActions: bindActionCreators(permissionActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Project)