import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as projectActions from "../../actions/projectActions"
import CustomModal from '../../components/modal'
import {useEffect, useState} from 'react'
import Button from '../../components/button'
// import './abi.css'
import Table from '../../components/table'
import {Form, Input, Tooltip} from 'antd'
import {
  InfoCircleOutlined
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea'


const getEmptyAbi = () => {
  return {
    contract: '',
    net: '',
    abi: ''
  }
}
function Formats(props) {
  const {formats} = props
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = Form.useForm();

  const items = formats.map((item, i) => {
    return {...item, number: i + 1}
})



  const columns = [
    {   
        dataIndex: 'number',
        width: '10%',        
    },
    {
        dataIndex: 'name',
        width: '40%',
        render: record => <div className="permission-name">
            {record.name}
        </div>
    },
    {
        dataIndex: 'description',
        width: '50%',
        render: record => <div className="permission-name">
            {record.description}
        </div>
    }
  ]

  const closeModal = () => {
    setModalVisible(false)
    form.setFieldsValue(getEmptyAbi())
  }

  const onFinish = values => {
    console.log(values)
    props.projectActions.addFormat(values)
    closeModal()
  }


  useEffect(() => {
    props.projectActions.getFormats()
  }, [])

  return(
      <div className='abi'>
          <div className='abi-head'>
              <div className='abi-title'>
                  Formats
              </div>
              <Button type={'primary'} style={{width: '20%'}} position={'end'} onClick={() => setModalVisible(true)}>Add format</Button>         
          </div>
          <Table
            columns={columns}
            items={items}
            rowStyle={{cursor: 'pointer'}}
          />
           <CustomModal
                isVisible={modalVisible}
                onCancel={closeModal}
                title={'Add new format'}
            >
                <Form
                    style={{padding: '0 150px'}}
                    name="add-validator"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                    flex: 1,
                    }}  
                    form={form}
                    onFinish={onFinish}
                >
                    <div className='form-group-label-group'>
                        <div className="extension-label">Name* </div> 
                        <Form.Item name={"name"} rules={[{ required: true }]}>
                        <Input
                            placeholder="Input name"
                            suffix={
                            <Tooltip title="Name">
                                <InfoCircleOutlined />
                            </Tooltip>
                            }
                        />
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <Form.Item name={"description"}>
                            <TextArea
                            rows={15}  
                            placeholder="Input description"
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" position={'center'} style={{textTransform: 'none', width: '30%'}}>
                            Save
                            {/* {props.isLoading ? <Spin /> : ""} */}
                        </Button>
                    </Form.Item>
                </Form>
            </CustomModal>
      </div>
  )
}

const mapStateToProps = state => ({
    isLoading: state.project.isLoading,
    formats: state.project.formats,
  })
  
  
  const mapDispatchToProps = dispatch => ({
    projectActions: bindActionCreators(projectActions, dispatch),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Formats)