import {all, put, takeLatest} from "redux-saga/effects"
import * as types from "../actions/types"
import axios from 'axios'

import { ENDPOINT } from "../config"

function* getAbis(action) {
    try {
        const permissions = yield axios.get(`${ENDPOINT}/dev-center/abi/`).then(res => res.data)
        yield put({type: types.SET_ABIS, payload: permissions.data})
    } catch(error) {
        // console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* addAbi(action) {
    const {data} = action
    try {
        const added = yield axios.post(`${ENDPOINT}/dev-center/abi/`, data).then(res => res.data)
        yield put({type: types.ABI_ADDED, payload: added.data.count === 0 ? {} : data})
    } catch(error) {
        // console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

export function* abiSaga() {
    yield all([
        yield takeLatest(types.GET_ABIS, getAbis),
        yield takeLatest(types.ADD_ABI, addAbi)
    ])
}