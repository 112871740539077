import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as extensionActions from "../../actions/extensionActions"
import * as buildActions from "../../actions/buildActions"
import './builds.css'
import React, { useEffect} from 'react';
import {  Spin, Row, Col } from 'antd';
import { NavLink, Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function Builds(props) {
    const {extensions, isLoading} = props
    const navigate = useNavigate()
    useEffect(()=>{
         props.buildActions.getUserBuildsByProjects() 
    }, [props.buildActions, props.extensionActions])

    useEffect(() => {
        const [ext] = extensions
        if(ext &&  ext.id) {
            // navigate(`app/${ext.id}`)
        }
    }, [navigate, extensions])


    const ext = extensions.map((j, index) => (
        <NavLink className='extensions-list' to={`app/${j.id}`} key={index} style={({ isActive }) => 
        (isActive ? {color: '#fff', background: '#6A4BFF' } : {color: '#6A4BFF', background: '#fff'})}>
             <div className='extensions-list-item'> {j.name}</div>
        </NavLink>
    ))


    return(
        <div className="builds">
            {isLoading ? (<Spin tip="Loading..." size='large'/>) :
            (
                 <Row style={{width: '100%'}}>
                    <Col style={{ 
                        display: 'flex', 
                        flexWrap: 'wrap',  
                        maxHeight: '85vh',
                        height: 'fit-content',
                        overflowY: 'auto', 
                        borderRadius: '8px',
                        marginRight: '20px'
                        }} span={4}>
                        {ext}
                    </Col>
                    <Col span={19} >
                        <Outlet/>
                    </Col>
                </Row>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.extension.isLoading,
    extensions: state.extension.extensions,
    userBuilds: state.build.userBuilds,
})


const mapDispatchToProps = dispatch => ({
    buildActions: bindActionCreators(buildActions, dispatch),
    extensionActions: bindActionCreators(extensionActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Builds)