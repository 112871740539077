import * as types from '../actions/types'

const initialState = {
    isLoading: false,
    builds: [],
    build: {},
    userBuilds: [],
    validation: {},
    reviews: [],
    error: {},
    permissions: [],
    nets: []
}

export default function buildReducer(state = initialState, action) {
    switch(action.type) {
        case types.GET_BUILDS:
            return {...state, isLoading: true}
        case types.SET_BUILDS:
            return {...state, isLoading: false, builds: action.payload}
      
        case types.GET_BUILD:
            return {...state, isLoading: true}
        case types.SET_BUILD:
            return {...state, isLoading: false, build: action.payload}

        case types.GET_BUILD_VALIDATION:
            return {...state, isLoading: true}
        case types.SET_BUILD_VALIDATION:
            return {...state, isLoading: false, validation: action.payload}

        case types.SET_REVIEWS:
            return {...state, isLoading: false, reviews: action.payload}

        case types.GET_USER_BUILDS:
            return {...state, isLoading: true}
        case types.SET_USER_BUILDS:
            return {...state, isLoading: false, userBuilds: action.payload}

        case types.ADD_BUILD:
            return {...state, isLoading: true}
        case types.ADDED_BUILD:
            return {...state, isLoading: false}

        case types.APPROVE:
            return {...state, isLoading: true}
        case types.APPROVED:
            return {...state, isLoading: false}

        case types.REJECT:
            return {...state, isLoading: true}
        case types.REJECTED:
            return {...state, isLoading: false}

        case types.ADD_REVIEW:
            return {...state, isLoading: true}
        case types.ADDED_REVIEW:
            return {...state, isLoading: false, reviews: [...state.reviews, action.payload]}

        case types.GET_BUILD_PERMISSIONS:
            return {...state, isLoading: true}
        case types.SET_BUILD_PERMISSIONS:
            return {...state, isLoading: false, permissions: action.payload}

        case types.GET_BUILD_NETS:
            return {...state, isLoading: true}
        case types.SET_BUILD_NETS:
            return {...state, isLoading: false, nets: action.payload}
      
        default:
            return state; 
    }
}