import TableRow from "./table-row"
import './table.css'

export default function Table(props) {
    const {columns, items, rowStyle, style, expandable, expandKey, expandColumns, needDeploy=true} = props
    let data_aray = items;
    if(!Array.isArray(items)) data_aray = Object.keys(items)
    const rows = data_aray.map((item, index) => 
        <TableRow 
            expandKey={expandKey} 
            expandColumns={expandColumns}
            expandable={expandable} 
            style={rowStyle} 
            item={item} 
            columns={columns}
            key={index}
            needDeploy={needDeploy}
        />
    )

    return(
        <div className="table" style={style}>
            {rows}
        </div>
    )
}