import './checkbox.css'
import {ReactComponent as CheckMark} from '../../assets/checkmark.svg';

export default function Checkbox(props) {
    const {checked = false, onChange, value, style} = props
    return(
        <div 
            onClick={() => onChange(!checked, value)} 
            className={`checkbox ${checked ? 'checkbox-active' : ''}`}  
            style={style}
        >
            {checked ? <CheckMark/> : ''}
        </div>
    )
}