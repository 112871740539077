import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as buildActions from "../../actions/buildActions"
import * as authActions from "../../actions/authActions"
import './build-detail.css'
import React, { useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';
import {Comment as CommentCard} from 'antd'
import BreadcrumbCommon from '../../components/breadcrumb'
import { List, Alert,Spin, Checkbox } from 'antd';
import moment from 'moment';
import CommonLayout from '../../components/commonLayout';
import BigCard from '../../components/bigCard';
import BigCardHeader from '../../components/bigCard/header';
import LabelText from '../../components/labelText';
import Comment from '../../components/comment';
import {PERMISSION_TYPE_COLORS, STATUS} from '../../common/constants'
import Button from '../../components/button'
import Accordion from '../../components/accordion'
import Table from '../../components/table'
import CardSelect from '../../components/card-select'

function capitalizeFirstLetter(string) {
  if(typeof string === 'string') 
  return string[0].toUpperCase() + string.slice(1);
}
const CommentList = ({comments}) => {
  // console.log(comments)
  return (
  <List
    dataSource={comments}
    style={{marginBottom: '20px'}}  
    header={`${comments.length} ${comments.length > 1 ? 'comments' : 'comment'}`}
    itemLayout="horizontal"
    renderItem={(props) => <CommentCard 
      className={`comment-card--${props.developers.role === 'admin' ? 'admin' : 'dev'}`}
      content={<p>{props.comment}</p>}
    
      author={props.developers.full_name}
      avatar={'https://joeschmoe.io/api/v1/random'}
      datetime={moment(props.date_created).fromNow()}
     />}
  />
)};



function BuildDetail(props) {
  const {validation, reviews, user, permissions, nets} = props
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState('');
  // const empty = 'No information, please fill.'
  // console.log(user)
  const handleSubmit = () => {
    if (!comment) return;
    setSubmitting(true);
    props.buildActions.createReview({validation_id: validation.id, comment})
    setTimeout(() => {
      setSubmitting(false);
      setComment('');
      setComments([
        ...comments,
        {
          author: 'Han Solo',
          avatar: 'https://joeschmoe.io/api/v1/random',
          content: <p>{comment}</p>,
          datetime: moment().fromNow(),
        },
      ]);
    }, 1000);
  };
  const handleApprove = () => {
    setSubmitting(true);
    props.buildActions.setApprove(validation.id)
    props.buildActions.getBuildValidation(id)
    setSubmitting(false);
  }
  const handleReject = () => {
    setSubmitting(true);
    props.buildActions.setReject(validation.id)
    props.buildActions.getBuildValidation(id) 
    setSubmitting(false);
  }

  const handleChange = (e) => {
    setComment(e.target.value);
  };
    const { id } = useParams();

    useEffect(()=>{
         props.buildActions.getBuildValidation(id) 
         props.buildActions.getBuildPermissions(id) 
         props.buildActions.getBuildNets(id) 
    }, [id, props.buildActions])

    const columns = [
      {
        dataIndex: 'readable_name',
        width: '15%',
        render: record => <div className="permission-name">
            {record.readable_name}
        </div>
      },
      {
        dataIndex: 'parameters',
        width: '70%',
        render: record => <div className="permission-parameter">{JSON.stringify(record.parameters)}</div>
    },
    {
        dataIndex: 'type',
        width: '15%',
        justify: 'flex-end',
        render: record => <div style={{color: PERMISSION_TYPE_COLORS[record.type]}}>
            {record.type}
        </div>
    },
    ]


    return(
        <div className="builds-container">
          <CommonLayout>
            <BreadcrumbCommon projectName={validation.extensions_descriptions?.name} projectId={validation.extensions_builds?.projects?.id} name={'Validation'} id={validation.id}/>
            <BigCard>
              <div className="build-upper">
                <BigCardHeader logo={validation.extensions_descriptions?.logo} name={validation.extensions_descriptions?.name} id={validation.extensions_descriptions?.id}/>
                <div className='build-right'>
                  <Alert message={validation?.status} type={STATUS[validation?.status]} />
                  <div className='build-date'>
                    <span>Upload date: </span>
                    {moment(validation.date_created).format('MMMM Do YYYY, h:mm a')}
                  </div>
                </div>
              </div>
              <div className='build-data--wrapper'>
                <div className='build-data'>
                  <span>Url:</span> 
                  <a target={'_blank'} href={validation.extensions_builds?.url} rel="noreferrer">
                  {validation.extensions_builds?.url}
                  </a>
                </div>
                <div className='build-data'>
                  <span>Developer:</span> {validation.developers?.full_name}
                </div>
              </div>
              <div className='build-data--wrapper'>
                <div className='build-data'>
                 <Checkbox className="buildCard-checkbox" checked={validation.build_after_review} >
                     </Checkbox>
                     Build immediately after review
                </div>
              </div>
              <LabelText label={'Git repositorry'} url={validation.extensions_builds?.projects?.git_url}/>
              <LabelText label={'Commit hash'} text={validation.extensions_builds?.commit_hash}/>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <LabelText style={{width: '100%'}} label={'Version'} text={validation.extensions_builds?.version}/>
                <LabelText style={{width: '100%', marginLeft: '50px'}} label={'Pipeline status'} text={capitalizeFirstLetter(validation.extensions_builds?.pipeline_status)} icon={validation.extensions_builds?.icon}/>       
              </div> 
               <Accordion
                isInitiallyVisible={false}
                title={'Networks'}
                theme={'dark'}
                bordered
              >
                <div className='build-permissions'>
                  {nets.map((item, i) => 
                  <CardSelect  
                    width={'calc(20% - 10px)'} 
                    key={i} 
                    style={{backgroundColor: '#F0F3FD'}}
                    img={item.logo} 
                    value={item.net}
                    />)}
                </div> 
              </Accordion>     
              <Accordion
                isInitiallyVisible={false}
                title={'Permissions'}
                bordered
                theme={'dark'}
              >
                  <Table
                      columns={columns}
                      items={permissions}
                      rowStyle={{backgroundColor: '#F0F3FD'}}
                  />
              </Accordion>        
              <div className="comment-container">
                <div className="comment-label">Comment</div>
                <Comment handleChange={handleChange}  value={comment} submitting={submitting}/>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Button position="start" loading={submitting} type="secondary" onClick={handleSubmit}>
                    Add comment
                  </Button>
                 {(user.role === 'admin' && validation.status !== 'approved' && validation.status !== 'rejected' &&  validation.status !== 'dev') ? 
                  <><Button position="center" type='danger'  onClick={handleReject}>Reject  {submitting ? <Spin /> : ""}</Button>                
                  <Button position="end" type='primary'  onClick={handleApprove}>Approve  {submitting ? <Spin /> : ""}</Button> </>             
                : ''}        
              </div>
              <CommentList comments={reviews}/>             
            </BigCard>
          </CommonLayout>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.extension.isLoading,
    builds: state.build.builds,
    validation: state.build.validation,
    reviews: state.build.reviews,
    user: state.auth.user,
    permissions: state.build.permissions,
    nets: state.build.nets
})


const mapDispatchToProps = dispatch => ({
    buildActions: bindActionCreators(buildActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BuildDetail)