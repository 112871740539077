import * as types from '../actions/types'

const initialState = {
    isLoading: false,
    protobufs: [],
    error: {},
}

export default function protobufReducer(state = initialState, action) {
    switch(action.type) {
        case types.GET_PROTOBUFS:
            return {...state, isLoading: true}
        case types.SET_PROTOBUFS:
            return {...state, isLoading: false, protobufs: action.payload}
        
        case types.ADD_PROTOBUF:
            return {...state, isLoading: true}
        case types.PROTOBUF_ADDED:
            return {...state, isLoading: false, abis: [...state.protobufs, {...action.payload, protobuf: action.payload}]}
        default:
            return state; 
    }
}