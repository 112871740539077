import './tooltip.css'

export default function Tooltip({text, children, visible}) {
    return(
        <div className="tooltip ">
            {children}
            <div className={`tooltip-text ${visible ? 'tooltip-visilbe' : 'tooltip-hidden'}`}>
                {text}
            </div>
        </div>
    )
}