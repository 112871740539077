import './accordion.css'
import { useState, useEffect } from 'react'
import {ReactComponent as Arrow} from '../../assets/arrow-down.svg';


export default function Accordion({onClick, setIsTappedGroup, isTappedGroup, title, children, isInitiallyVisible, mode, headerStyle, theme, cornered, bordered, icon, closeOthers,index}) {
    const [isVisible, setIsVisible] = useState(isInitiallyVisible)

    useEffect(() => {
        if(!isTappedGroup?.length)
        setIsVisible(isInitiallyVisible)
    }, []);


    const onToogle = () => {
        if(!isTappedGroup?.length) {
            setIsVisible(!isVisible)
            return
        }
        if (onClick) {
            console.log(isTappedGroup.find(item => item.index === index))
            onClick(+index, isTappedGroup.find(item => item.index === index).value)
        }
        if(closeOthers) {
            let accordions = [...isTappedGroup]
            for (let i = 0; i < accordions.length; i++) {
                if(accordions[i].value && accordions[i].index === index) {
                    accordions[i].value = false
                    accordions[i].isOpenFirst = true
                    break
                }
                if (accordions[i].index === index) {
                 
                    accordions[i].value = true
                } else {
                    accordions[i].value = false
                    accordions[i].isOpenFirst = true
                }
            } 
            setIsTappedGroup(accordions)
        }  
    }

    const isGroupVisible = () => isTappedGroup && isTappedGroup.find(item => item.index === index).value
    

    return(
        <div className='accordion'>
            <div style={headerStyle} className={`accordion-header ${bordered && 'bottom-border'} ${cornered && 'cornered'} ${(isTappedGroup?.length ? isGroupVisible() : isVisible) && mode}`}  onClick={onToogle}>
                <div style={{fontSize: headerStyle?.fontSize, color: headerStyle?.color}} className={`accordion-title ${(isTappedGroup?.length ? isGroupVisible() : isVisible) ? `${theme} active` : ''}`}>
                    {title}
                    {icon}
                </div>
                <div>
                    <Arrow  className={`accordion-icon ${(isTappedGroup?.length ? isGroupVisible() : isVisible) ? `${theme} active` : ''}`}/>
                </div>
            </div>
            <div className='accordion-body'>
                {(isTappedGroup?.length ? isGroupVisible() : isVisible) ? children : ''}
            </div>       
        </div>
    )
}