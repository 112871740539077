export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'

export const SIGN_IN = 'SIGN_IN'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const GET_EXTENSIONS = 'GET_EXTENSIONS'
export const SET_EXTENSIONS = 'SET_EXTENSIONS'

export const GET_EXTENSION = 'GET_EXTENSION'
export const SET_EXTENSION = 'SET_EXTENSION'

export const SET_EXTENSIONS_BY_PROJECT = 'SET_EXTENSIONS_BY_PROJECT'
export const GET_EXTENSIONS_BY_PROJECT = 'GET_EXTENSIONS_BY_PROJECT'

export const ADD_EXTENSION = 'ADD_EXTENSION'
export const ADDED_EXTENSION = 'ADDED_EXTENSION'

export const GET_BUILDS = 'GET_BUILDS'
export const SET_BUILDS = 'SET_BUILDS'

export const GET_BUILD = 'GET_BUILD'
export const SET_BUILD = 'SET_BUILD'

export const GET_USER_BUILDS = 'GET_USER_BUILDS'
export const SET_USER_BUILDS = 'SET_USER_BUILDS'

export const ADD_BUILD = 'ADD_BUILD'
export const ADDED_BUILD = 'ADDED_BUILD'

export const GET_BUILD_VALIDATION = 'GET_BUILD_VALIDATION'
export const SET_BUILD_VALIDATION = 'SET_BUILD_VALIDATION'

export const APPROVE = 'APPROVE'
export const APPROVED = 'APPROVED'

export const REJECT = 'REJECT'
export const REJECTED = 'REJECTED'

export const GET_REVIEWS = 'GET_REVIEWS'
export const SET_REVIEWS = 'SET_REVIEWS'

export const GET_PROJECTS = 'GET_PROJECTS'
export const SET_PROJECTS = 'SET_PROJECTS'

export const GET_PROJECT = 'GET_PROJECT'
export const SET_PROJECT = 'SET_PROJECT'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETED_PROJECT = 'DELETED_PROJECT'

export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATED_PROJECT = 'UPDATED_PROJECT'

export const GET_TOKEN = 'GET_TOKEN'
export const SET_TOKEN = 'SET_TOKEN'

export const ADD_PROJECT = 'ADD_PROJECT'
export const ADDED_PROJECT = 'ADDED_PROJECT'

export const ADD_REVIEW = 'ADD_REVIEW'
export const ADDED_REVIEW = 'ADDED_REVIEW'

export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'
export const ClEAN_ERROR = 'CLEAN_ERROR'

export const LOGOUT = 'LOGOUT'

export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const PERMISSIONS_RECEIVED = 'PERMISSIONS_RECEIVED'

export const UPDATE_PERMISSION = 'UPDATE_PERMISSION'
export const PERMISSION_UPDATED = 'PERMISSION_UPDATED'

export const DELETE_PERMISSION = 'DELETE_PERMISSION'
export const PERMISSION_DELETED = 'PERMISSION_DELETED'

export const ADD_PERMISSION = 'ADD_PERMISSION'
export const PERMISSION_ADDED = 'PERMISSION_ADDED'

export const GET_NETS = 'GET_NETS'
export const SET_NETS = 'SET_NETS'

export const GET_FORMATS = 'GET_FORMATS'
export const SET_FORMATS = 'SET_FORMATS'

export const ADD_FORMAT = 'ADD_FORMAT'
export const FORMAT_ADDED = 'FORMAT_ADDED'

export const GET_PROJECT_PERMISSIONS = 'GET_PROJECT_PERMISSIONS'
export const SET_PROJECT_PERMISSIONS = 'SET_PROJECT_PERMISSIONS'

export const GET_PROJECT_NETS = 'GET_PROJECT_NETS'
export const SET_PROJECT_NETS = 'SET_PROJECT_NETS'

export const GET_PROJECT_FORMATS = 'GET_PROJECT_FORMATS'
export const SET_PROJECT_FORMATS = 'SET_PROJECT_FORMATS'

export const ADD_NETS_TO_PROJECT = 'ADD_NETS_TO_PROJECT'
export const ADDED_NETS_TO_PROJECT = 'ADDED_NETS_TO_PROJECTS'

export const ADD_FORMATS_TO_PROJECT = 'ADD_FORMATS_TO_PROJECT'
export const ADDED_FORMATS_TO_PROJECT = 'ADDED_FORMATS_TO_PROJECTS'

export const ADD_PERMISSIONS_TO_PROJECT = 'ADD_PERMISSIONS_TO_PROJECT'
export const ADDED_PERMISSIONS_TO_PROJECT = 'ADDED_PERMISSIONS_TO_PROJECTS'

export const GET_BUILD_PERMISSIONS = 'GET_BUILD_PERMISSIONS'
export const SET_BUILD_PERMISSIONS = 'SET_BUILD_PERMISSIONS'

export const GET_BUILD_NETS = 'GET_BUILD_NETS'
export const SET_BUILD_NETS = 'SET_BUILD_NETS'

export const ADD_ABI = 'ADD_ABI'
export const ABI_ADDED = 'ABI_ADDED'

export const GET_ABIS = 'GET_ABIS'
export const SET_ABIS = 'SET_ABIS'

export const GET_PERMISSION_BUILDS = 'GET_PERMISSION_BUILDS'
export const SET_PERMISSION_BUILDS = 'SET_PERMISSION_BUILDS'

export const ADD_PROTOBUF = 'ADD_PROTOBUF'
export const PROTOBUF_ADDED = 'PROTOBUF_ADDED'

export const GET_PROTOBUFS = 'GET_PROTOBUFS'
export const SET_PROTOBUFS = 'SET_PROTOBUFS'

export const GET_PROJECT_TOKEN = 'GET_PROJECT_TOKEN'
export const SET_PROJECT_TOKEN = 'SET_PROJECT_TOKEN'
