import Table from "../../components/table";
import { PERMISSION_TYPE_COLORS } from "../../common/constants";
import Button from "../../components/button";
import {ReactComponent as Plus} from '../../assets/plus.svg';
import {ReactComponent as Close} from '../../assets/close.svg';
import {ReactComponent as Search} from '../../assets/search.svg';
import { useState } from "react";
import CustomModal from "../../components/modal";
import Checkbox from "../../components/checkbox";
import Input from "../../components/input";
import Warning from "../../components/modal/warning";
import { useEffect } from "react";
import List from "../../components/list";

export default function BuildPermission({permissions, allPermissions, projectActions, projectId}) {
    const [modalVisible, setModalVisible] = useState(false)
    const [selectedPermisisons, setSelectedPermissions] = useState([])
    const [warningModalVisible, setWarningModalVisible] = useState(false)
    const [deleteId, setDeleteId] = useState(0)

    useEffect(() => {
        setSelectedPermissions(permissions)
    }, [permissions])


    const permissionOptionsColumns = [
        {
            width: '10%',
            render: record => <Checkbox value={record} onChange={onChange} checked={selectedPermisisons.some(p => p.id === record.id)}/>
        },
        {   
            width: '20%',
            dataIndex: 'readable_name',
            render: record => <div className="permission-name">
                {record.readable_name}
            </div>
        },
        {
            dataIndex: 'parameters',
            width: '70%',
            render: record => <div className="permission-parameter">{JSON.stringify(record.parameters)}</div>
        },
    ]

    const onChange = (checked, value) => {
        if (checked) {
            setSelectedPermissions([...selectedPermisisons, value])
        } else {
            setSelectedPermissions(selectedPermisisons.filter(p => p.id !== value.id))
        }
    }

    const onSearch = e => {
        
    }

    const closeModal = () => {
        setSelectedPermissions([])
        setModalVisible(false)
    }

    const onSave = (e) => {
        e.preventDefault()
        projectActions.addPermissionsToProject({id: projectId, permissions: selectedPermisisons})
        closeModal()
    }
    
    return(
        <>  
           
            <div className="project-permissions">
                <Button 
                    onClick={(e) => {
                        e.preventDefault();
                        setModalVisible(true)
                        }}
                    position={'center'} 
                    style={{width: '100%',
                            borderRadius: '4px', 
                            backgroundColor: '#E8F7FF',
                            border: '1px solid #00A3FF',
                            color: '#00A3FF',
                            textTransform: 'none',
                            fontSize: '18px',
                            boxShadow: 'none',
                }}>
                    <div className="add-permission">
                        <Plus fill='#fff' stroke="#fff" />
                    </div>
                    Add permissions
                </Button>
                <List
                   
                    items={permissions.map(item => 
                         { return {
                        ...item, 
                        typeStyle: PERMISSION_TYPE_COLORS[item.type],
                        actions: <div className="project-permission--delete" onClick={() => {
                                        setDeleteId(item.id)
                                        setWarningModalVisible(true)
                                    }}>
                                    <Close fill="#fff"/>
                                </div> }})}           
                />       
            </div>       
            <CustomModal
                isVisible={modalVisible}
                title={'Add permissons to project'}
                onCancel={closeModal}
            >
                <Input 
                    type={'text'} 
                    hint={'Search permisson'} 
                    placeholder={'Search to select'} 
                    prefix={<Search/>}
                    onChange={onSearch}
                />
                <Table
                    columns={permissionOptionsColumns}
                    items={allPermissions}
                    rowStyle={{background: '#F0F3FD'}}
                    style={{maxHeight: '300px'}}
                />
                <Button 
                    onClick={onSave}
                    type={'primary'} 
                    position={'center'} 
                    style={{width: '35%'}}
                >
                    Save
                </Button>
            </CustomModal> 
            <CustomModal
                title=""
                isVisible={warningModalVisible}
                onCancel={() => setWarningModalVisible(false)}
                color="#FA3B33"
                hideClose
            >
                <Warning
                    title={'Do you want delete permission?'}
                    onFinish={(e) => {
                        e.preventDefault();
                                projectActions.addPermissionsToProject(
                {
                    id: projectId, 
                    permissions: permissions.filter(item => item.id !== deleteId)
                })
                               }}
                    onCancel={() => setWarningModalVisible(false)}
                />
            </CustomModal>    
        </>
    )
}