import './breadcrumb.css'
import {Link} from 'react-router-dom'

function BreadcrumbCommon(props) {
    const {projectName, name, id, projectId} = props
    return (
        <div className='breadcrumb-common'>
            <Link to={`/projects/${projectId}`} className='breadcrumb-project--name'>{projectName}</Link>
            <img src={'/img/icons/arrow.svg'} alt={'arrow'}/>
            <div className='breadcrumb-extension--name'>{name} <span>({id})</span></div>
        </div>
    )
}

export default  BreadcrumbCommon