import {ReactComponent as Delete} from '../../assets/close.svg';
import './card-select.css'
import {QuestionCircleOutlined} from '@ant-design/icons'

export default function CardSelect({value, img, width = '10%', onTap, hasAction, hasImg=true, style}) {
    return(
        <div className="card-select" style={{...style, width}}>
            {hasImg ?
             <div className="card-select--img" >
               {img ? <img src={img} alt={value} /> :  <QuestionCircleOutlined style={{ marginRight: "10px"}} />}
            </div> : <></>}
            <div className="card-select--name">
                {value}
            </div>
            {hasAction && <div className='card-select--icon' onClick={onTap}>
                <Delete fill='#FA3B33'/>
            </div>}       
        </div>
    )
}