import {Form, Select, Input, Tooltip, Checkbox} from 'antd'
import {
    InfoCircleOutlined,
  } from '@ant-design/icons';
import { useState } from 'react';

const {Option} = Select

function BuildInfo({changeProject, projects, changeAppType, onKeyUp}) {
    const [isSendToreview, setIsSendToreview] = useState(true)
    const isSendReviewChecked = async (e) =>{
      await setIsSendToreview(!isSendToreview)
    }
    return (
        <div>
             <div className='form-group-label-group'>
                  <div className="extension-label">Project *</div> 
                    <Form.Item name={"project_id"}  rules={[{ required: true }]}>
                      <Select
                        showSearch={false}
                        placeholder="Select a project"
                        optionFilterProp="children"
                        onChange={changeProject}
                        // onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                      >
                      {projects}
                      </Select>
                    </Form.Item>
                </div>
                <div className='form-group-label-group'>
                  <div className="extension-label">Hash of commit * </div> 
                  <Form.Item name={"commit_hash"} rules={[{ required: true }]}>
                    <Input
                      name={'commit_hash'}
                      onKeyUp={onKeyUp}
                      placeholder="6dcbef857a67e13d008120ee157c5ecae9755141"
                      suffix={
                        <Tooltip title="Git hash of commit">
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </div>
                <div className='form-group-label-group'>
                  <div className="extension-label">Build version * </div> 
                  <Form.Item name={"version"} rules={[{ required: true }]}>
                    <Input
                     name={'version'}
                     onKeyUp={onKeyUp}
                      placeholder="1.0.0"
                      suffix={
                        <Tooltip title="Build version">
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </div>
                <div className='form-group-label-group'>
                  <div className="extension-label">App type *</div> 
                    <Form.Item name={"app_type"}  rules={[{ required: true }]}>
                      <Select
                        showSearch={false}
                        placeholder="Select a type"
                        optionFilterProp="children"
                        onChange={changeAppType}
                        // onSearch={onSearch}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                      >
                      <Option key={1} value={'appSer'}>Application + Service</Option>
                      <Option key={2} value={'app'}>Application</Option>
                      <Option key={3} value={'ser'}>Service</Option>
                      </Select>
                    </Form.Item>
                </div>
                <div className='form-group-label-group'>
                  <div className="extension-label after-review">Send to review (In order to update build without review in dev, remove checkmark)</div> 
                  <Form.Item  name={"is_send_to_review"} onChange={isSendReviewChecked} valuePropName="checked">
                    <Checkbox></Checkbox>
                  </Form.Item>
                </div>
                <div className='form-group-label-group'>
                  <div className="extension-label after-review">Publish immediately after review</div> 
                  <Form.Item  name={"build_after_review"} valuePropName="checked">
                    <Checkbox disabled={isSendToreview}></Checkbox>
                  </Form.Item>
                </div>
                {/* <div className='form-group-label-group'>
                  <div className="extension-label after-review">Has UI?</div> 
                  <Form.Item  name={"has_ui"} valuePropName="checked">
                    <Checkbox></Checkbox>
                  </Form.Item>
                </div>
                <div className='form-group-label-group'>
                  <div className="extension-label after-review">Has API?</div> 
                  <Form.Item  name={"has_api"} valuePropName="checked">
                    <Checkbox></Checkbox>
                  </Form.Item>
                </div> */}
        </div>
    )
}

export default BuildInfo