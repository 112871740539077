
import { connect } from "react-redux";
import {Navigate, Outlet} from 'react-router';


const AdminRoute=({auth})=>{
    // console.log(auth)
    return(

                auth.isAuth
                    ? (
                        <Outlet/>
                    ) : (
                        <Navigate to="/signin"/>
                    )
            
    );
                  
}


const mapStateToProps=state=>({
    auth:state.auth
});

export default connect(mapStateToProps)(AdminRoute);