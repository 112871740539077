import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as extensionActions from "../../actions/extensionActions"
import * as buildActions from "../../actions/buildActions"
import * as projectActions from "../../actions/projectActions"
import * as permissionActions from "../../actions/permissionActions"

import './create-build.css'

import { Form, Spin, Select} from 'antd';

import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router';

import Accordion from '../../components/accordion'
import BuildInfo from '../../components/build-info'
import Button from '../../components/button'
import {ReactComponent as CheckMark} from '../../assets/checkmark.svg';
import BuildDescription from '../../components/build-description'
import ProjectNetwork from '../project-network'
import ProjectFormats from '../project-formats'
import BuildPermission from '../build-permission';
import {
  WarningOutlined
} from '@ant-design/icons';
const { Option } = Select;
const iconStyle = { 
      backgroundColor: '#0FB774', 
      borderRadius: '50%', 
      width: '20px', 
      height: '20px', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      marginLeft: '10px'
}

const buildInfoRequiredFields = ['project_id', 'app_type', 'commit_hash', 'version']
const buildDescriptionServiceRequiredFields = ['name', 'logo']
const buildDescriptionRequiredFields = ['name', 'short_description', 'description', 'logo']

function CreateBuild(props) {
    const navigate = useNavigate()
    let [isTappedGroup, setIsTappedGroup] = useState([
      {index: '1', value: true, isOpenFirst: false}, 
      {index: '2', value: false, isOpenFirst: false}, 
      {index: '3', value: false, isOpenFirst: false}
    ])
    let [step, setStep] = useState(1)
    const [form] = Form.useForm();
    const[formValues, setValues] = useState({})
    const [projectId, setProjectId] = useState(null)
    const [preview, setPreview] = useState({
      name: 'My App',
      cardDescription: 'My app enables users to access awesome stuff across various ecosystems',
      shortDescription: 'My app type',
      color: '#fff',
      description: '',
      video: ''
    })
    const {extensions, isLoading, nets, formats, permissions, project_nets, project_formats, project_permissions} = props;
    const [descriptionOptions, setdescriptionOptions] = useState('');
    const [descriptions, setDescriptions] = useState({});
    const [appPropertiesFilled, setAppPropertiesFilled] = useState(false)
    const [appType, setAppType] = useState('')
    const [currentDescription, setDescription] = useState({
      id: null,
      name: '',
      short_description: '',
      description: '',
      logo: '',
      background: '',
      background_color: '',
      url: null,
      url_video: null,
      what_you_can: null,
      is_prod: null,
      is_dev: null,
      card_description: null,
      url_prod: null,
      project_id: 2,
      project_name: '',
      app_status: '',
      build_status: ''
    });
    const [specificFields, setSpecificFields] = useState({
      pickerValue : {},
      color:'',
    })
   
    useEffect(()=>{
      props.extensionActions.getExtensions() 
      props.projectActions.getNets()
      props.projectActions.getFormats()
      props.permissionActions.getPermissions()
     
     }, [props.extensionActions])

     useEffect(() => { 
      if (projectId && step === 2) {
        props.projectActions.getProjectNets(projectId) 
        props.projectActions.getProjectFormats(projectId) 
        props.projectActions.getProjectPermissions(projectId) 
      }
     }, [projectId, step])

    const onFinish = (values) => {
      const { name, short_description, description, card_description, logo, background_color, url_video} = values
      if (step === 3) {
        // console.log('enter')
        values.isNewDescription = isDescriptionChanged({ name, short_description, description, card_description, logo, background_color, url_video})
        values.logo = values.logo?.file;
        values.background_color = values.background_color?.hex || '#fff'
      }
    
      values.has_api = values.app_type === 'appSer' || values.app_type === 'ser'
      values.has_ui = values.app_type === 'appSer' || values.app_type === 'app'
      if (step === 1 && form.getFieldsError(['project_id', 'commit_hash', 'version', 'app_type']).map(field => field.errors.length).every(error => error === 0)) {
        changePanelVisible('2')
        setStep(2)
      } else if (step === 2) {
        changePanelVisible('3')
        setStep(3)
        setAppPropertiesFilled(true)
      } else if (step === 3 && form.getFieldsError(['name', 'short_description', 'description', 'logo']).map(field => field.errors.length).every(error => error === 0)) {
        // changePanelVisible('3')
        // setStep(1)
      }
      setValues({...formValues, ...values})
     
      console.log({...formValues, ...values})

      if(step == 3) props.buildActions.create({...formValues, ...values}, navigate)
    };   


  
   

    const onChangeProjectSelect = (id) => { 
      let exs = []
      extensions.forEach(element => {
        if(element.id === id) {
          exs =  element.extensions.map((ext, key) => (<Option key={key} value={ext.id}>{`${ext.name}  №${ext.id}`}</Option>))
          setDescriptions(element.extensions)
        }
      });
      setProjectId(id)
      setValues({...formValues, project_id: id})
      setdescriptionOptions(exs)
    }

    const onChangeAppType = value => {
      setAppType(value)
      setValues({...formValues, app_type: value})
    }

    const onChangeDescription = (id) => {
      let ex =  descriptions.find(ext => ext.id === id )
      form.setFieldsValue({...ex})
      setValues({...formValues, ...ex})
      setDescription({...currentDescription, ...ex})
      setSpecificFields({...specificFields, color: ex.background_color, pickerValue: {hex: ex.background_color}})
      setPreview({...preview,
        logo: ex.logo,
        video: ex.url_video && ex.url_video != 'undefined' ? ex.url_video : '',
        name: ex.name,
         color: ex.background_color, description: ex.description, shortDescription: ex.short_description, cardDescription: ex.card_description})
      // console.log({...specificFeailds, color: ex.background_color, pickerValue: {hex: ex.background_color, hsl: hexToHsl(ex.background_color)}})
    }
    useEffect(() => {
    }, [isLoading])
    const projectOptions = extensions.map((i,key)=>(
      <Option key={key} value={i.id}>{i.name}</Option>
    ))

    function isDescriptionChanged (values) {
      if (!currentDescription.name)  return true
      const { name, short_description, description, card_description, logo, background_color, url_video} = currentDescription;
      // console.log({values, cur :{ name, short_description, description, card_description, logo, background_color, url_video} })

      return !JSON.stringify(values) === JSON.stringify({ name, short_description, description, card_description, logo, background_color, url_video})
    }

    const changePanelVisible = (index) => {
      let accordions = [...isTappedGroup]
      for (let i = 0; i < accordions.length; i++) {
        if(accordions[i].value && accordions[i].index === index) {
            accordions[i].value = false
            accordions[i].isOpenFirst = true
            break
        }
        if (accordions[i].index === index) {
            accordions[i].value = true
        } else {
            accordions[i].value = false
            accordions[i].isOpenFirst = true
        }
    } 
    setIsTappedGroup(accordions)
    }

    const handleSteps = (step, value) => {
      if (step === 2 && !appPropertiesFilled) {
        if(value)  setAppPropertiesFilled(true)   
      } else if (!appPropertiesFilled) {
        if(!value)  setAppPropertiesFilled(true) 
      }
      setStep(step)
    }

    const onKeyUp = (e) => {
      console.log(e)
      setValues({...formValues, [e.target.name]: e.target.value})
    }


    console.log(formValues)
      console.log(appType)
      console.log(project_formats)

    // console.log(isTappedGroup[0].isOpenFirst)
    // console.log(buildInfoRequiredFields.map(field => formValues[field]))
    return(
        <div>
           <Form
            style={{padding: '0 150px'}}
            name="add-validator"
            labelAlign="left"
            labelWrap
            wrapperCol={{
              flex: 1,
            }}  
            form={form}
            onFinish={onFinish}
          >
            <div className="form-group-background">
              <Accordion
                index={'1'}
                isTappedGroup={isTappedGroup}
                setIsTappedGroup={setIsTappedGroup}
                title={`Build info`}
                headerStyle=
                {{fontSize: '20px', color: `${buildInfoRequiredFields.every(field => formValues[field]) ? '#4B4C63' : '#8B9BC7'}`,  borderBottom:
                 `3px solid ${buildInfoRequiredFields.every(field => formValues[field]) ? '#0FB774' : '#F0F3FD'}` , paddingBottom : '10px'}}
                theme={'dark'}
                icon={buildInfoRequiredFields.every(field => formValues[field]) ?  <div style={iconStyle}><CheckMark/></div>  : isTappedGroup[0].isOpenFirst ?  <WarningOutlined style={{ color: 'orange', marginLeft: '10px' }} />  : '' }
                isInitiallyVisible
                closeOthers
                onClick={handleSteps}
              >
                <BuildInfo
                  changeProject={onChangeProjectSelect}
                  changeAppType={onChangeAppType}
                  projects={projectOptions}
                  onKeyUp={onKeyUp}
                />
              </Accordion>
              <Accordion
                index={'2'}
                isTappedGroup={isTappedGroup}
                setIsTappedGroup={setIsTappedGroup}
                title={`Application properties`}
                headerStyle=
                {{fontSize: '20px', color: `${ project_nets.length ? '#4B4C63' : '#8B9BC7'}`,  borderBottom:
                 `3px solid ${ project_nets.length ? '#0FB774' : '#F0F3FD'}` , paddingBottom : '10px'}}
                theme={'dark'}
                icon={project_nets.length ? <div style={iconStyle}><CheckMark/></div> : isTappedGroup[1].isOpenFirst ?  <WarningOutlined style={{ color: 'orange', marginLeft: '10px' }} />  : '' }
                fulfilled={(project_nets.length)}
                isInitiallyVisible
                closeOthers
                onClick={handleSteps}
              >
                <div>
                  <div className='block-title'>Network</div>
                  <ProjectNetwork bordered style={{cardWidth: 'calc(20% - 10px)'}} allNets={nets} projectActions={props.projectActions}  nets={project_nets} projectId={projectId}/>
                </div>
                {(appType === 'appSer' || appType === 'ser') &&<div>
                  <div className='block-title'>Format</div>
                  <ProjectFormats  hasImg icon={'img/icons/link.svg'} bordered   style={{cardWidth: 'calc(20% - 10px)'}}  allFormats={formats} projectActions={props.projectActions}  formats={project_formats} projectId={projectId}/>
                </div>}
                <div>
                  <div className='block-title'>Permissions</div>
                  <BuildPermission  projectId={projectId} projectActions={props.projectActions} allPermissions={permissions} permissions={project_permissions}/>
                </div>
              </Accordion>
             <Accordion
                index={'3'}
                isTappedGroup={isTappedGroup}
                setIsTappedGroup={setIsTappedGroup}
                title={`Description`}
                headerStyle=
                {{fontSize: '20px',  color: `${(appType === 'ser' || !appType ? buildDescriptionServiceRequiredFields.every(field => formValues[field]) :  buildDescriptionRequiredFields.every(field => formValues[field])) ? '#4B4C63' : '#8B9BC7'}`,  borderBottom:
                 `3px solid ${(appType === 'ser' || !appType ? buildDescriptionServiceRequiredFields.every(field => formValues[field]) :  buildDescriptionRequiredFields.every(field => formValues[field])) ? '#0FB774' : '#F0F3FD'}` , paddingBottom : '10px'}}
                theme={'dark'}
                icon={(appType === 'ser' || !appType ? buildDescriptionServiceRequiredFields.every(field => formValues[field]) :  buildDescriptionRequiredFields.every(field => formValues[field])) ? <div style={iconStyle}><CheckMark/></div> : isTappedGroup[2].isOpenFirst ?  <WarningOutlined style={{ color: 'orange', marginLeft: '10px' }} />  : ''}
                bordered
                closeOthers
                onClick={handleSteps}
              >
                <BuildDescription
                  appType={appType}
                  preview={preview}
                  setPreview={setPreview}
                  onChangeDescription={onChangeDescription}
                  descriptionOptions={descriptionOptions}
                  specificFields={specificFields}
                  setSpecificFields={setSpecificFields}
                  onKeyUp={onKeyUp}
                  setFormValue={setValues}
                />
              </Accordion> 
            
              
                <Form.Item>
                   <Button type={'primary'} position={'center'} style={{width: '20%', marginTop: '40px'}}>  
                    {step !== 3 ? 'NEXT' : 'Submit'}
                    {isLoading ? <Spin /> : ""}
                   </Button>
                  
                </Form.Item>
              </div>
             
             
            
          </Form> 
          
        </div>
  )
}

const mapStateToProps = state => ({
  isLoading: state.build.isLoading,
  extensions: state.extension.extensions,
  nets: state.project.nets,
  formats: state.project.formats,
  permissions: state.permission.permissions,
  project_nets: state.project.project_nets,
  project_formats: state.project.project_formats,
  project_permissions: state.project.project_permissions,
})


const mapDispatchToProps = dispatch => ({
  buildActions: bindActionCreators(buildActions, dispatch),
  extensionActions: bindActionCreators(extensionActions, dispatch),
  projectActions: bindActionCreators(projectActions, dispatch),
  permissionActions: bindActionCreators(permissionActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateBuild)