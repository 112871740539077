import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Input, Form, Tooltip} from 'antd'
import * as authActions from "../../actions/authActions"
import './signup.css'
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useState } from 'react'
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import Button from '../../components/button';
import {Link} from 'react-router-dom';

function Signup(props) {
    const [pwd, setPwd] = useState('')
    const navigate = useNavigate()

    const onFinish = (values) => {
        delete values.password_confirm
     props.authActions.signUp(values, navigate)
    };
    
    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if(props.isAuth){
            navigate('/projects')
        }
    },[navigate, props.isAuth])

    const validatePassword = (rule, value, callback) => {
        if (value && value.length < 8) {
          callback("Password must be more than 8 symbols!");
        } else {
          callback();
        }
    }
    const validateConfirm = (rule, value, callback) => {
        if (value && value !== pwd) {
          callback("Enter same password!");
        } else {
          callback();
        }
    }

    return(
        <div>
            <div className='form-auth'>
                <div className='form-auth-logo'><img className='form-auth-logo-inner'  alt= 'logo' src='img/icons/logo.svg'></img></div>
                <Form
                    name="basic"
                    // labelCol={{ span: 5 }}
                    // wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='form-auth-inner'
                    >        
                    <div className='form-group-label-group'>
                        <div className="extension-label">Email * </div>      
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please, enter correct email address!' , type: 'email' }]}
                        >
                            <Input
                             suffix={
                            <Tooltip title="Email">
                                <MailOutlined />
                            </Tooltip>
                        }
                             className='form-auth-input' placeholder='Enter email'/>
                        </Form.Item>  
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Username * </div>      
                        <Form.Item
                            name="full_name"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input
                            suffix={
                                <Tooltip title="Username">
                                    <InfoCircleOutlined />
                                </Tooltip>
                            }
                            placeholder='Enter username'/>
                        </Form.Item>  
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Password * </div>  
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }, { validator: validatePassword }]}
                        >
                            <Input.Password placeholder='Enter password' 
                                onChange={(e) => setPwd(e.target.value)}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                /> 
                        </Form.Item>  
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Confirm password * </div>  
                        <Form.Item
                            name="password_confirm"
                            rules={[{ required: true, message: 'Please confirm your password!' }, { validator: validateConfirm }]}
                        >
                            <Input.Password placeholder='Confirm password'/> 
                        </Form.Item> 
                    </div>  
                    <div className='form-group-label-group-center'>
                        <Button htmlType="submit" type={'primary'} position={'center'} hasGap>
                            Sign up
                        </Button>
                    </div>
                    <Link to={'/signin'}>Already have an account?</Link>

                </Form>
        </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading,
    user: state.auth.user,
    isAuth: state.auth.isAuth
})


const mapDispatchToProps = dispatch => ({
    authActions: bindActionCreators(authActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)