import Button from "../../button"
import './warning.css'

export default function Warning(props) {
    const {title, onFinish, onCancel} = props
    return(
            <>
                <div className="warning-modal--head">
                   {title}
                </div>
                <div className="warning-modal--body">
                    <Button style={{width: '35%'}} type={'danger'} position={'center'} onClick={(e) => {
                        onFinish(e)
                        onCancel()
                        }}>
                            Yes
                    </Button>
                    <Button type={'default'} position={'center'} onClick={onCancel}>
                            No
                    </Button>
                </div>
            </>
    )
}
