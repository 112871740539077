import {all, put, takeLatest} from "redux-saga/effects"
import * as types from "../actions/types"
import axios from 'axios'

import { ENDPOINT } from "../config"

function* getBuilds(action) {
    try {
        const {data} = action
        const buildsResponse = yield axios.get(`${ENDPOINT}/dev-center/builds/${data}`).then(res => res.data)
        yield put({type: types.SET_BUILDS, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* setApprove(action) {
    try {
        const {data} = action
        const buildsResponse = yield axios.post(`${ENDPOINT}/dev-center/admin/builds/approve/${data}`).then(res => res.data)
        yield put({type: types.APPROVED, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}
function* setReject(action) {
    try {
        const {data} = action
        const buildsResponse = yield axios.post(`${ENDPOINT}/dev-center/admin/builds/reject/${data}`).then(res => res.data)
        yield put({type: types.REJECTED, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getBuild(action) {
    try {
        const {data} = action
        const buildsResponse = yield axios.get(`${ENDPOINT}/dev-center/builds/${data}`).then(res => res.data)
        yield put({type: types.SET_BUILDS, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getValidationAndReviews(action) {
    try {
        const {data} = action
        const buildsResponse = yield axios.get(`${ENDPOINT}/dev-center/builds/validation/${data}`).then(res => res.data)
        yield put({type: types.SET_BUILD_VALIDATION, payload: buildsResponse.data})
        yield put({type: types.SET_REVIEWS, payload: buildsResponse.data.validations_reviews})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getUserBuildsByProjects(action) {
    try {
        const buildsResponse = yield axios.get(`${ENDPOINT}/dev-center/builds/getUserBuildsByProjects`).then(res => res.data)
        yield put({type: types.SET_USER_BUILDS, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* create(action) {
    try {
        const {data, navigate} = action
        const dataFm = new FormData();
        for(let i in data){
            dataFm.append(i, data[i])
        }
        const buildsResponse = yield axios.post(`${ENDPOINT}/dev-center/builds`, dataFm, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then(res => res.data)
        yield put({type: types.ADDED_BUILD, payload: buildsResponse.data})
        yield put({type: types.SUCCESS, success: {
            message: "Build successfully created",
            description: "Build created and sended for review"
        }})
        navigate('/projects/' + data.project_id)
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* createReview(action) {
    const {data} = action
    try {
        const reviewResponse = yield axios.post(`${ENDPOINT}/dev-center/reviews/`, data).then(res => res.data)
        yield put({type: types.ADDED_REVIEW, payload: reviewResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getBuildPermissions(action) {
    const {data} = action
    try {
        const permResponse = yield axios.get(`${ENDPOINT}/dev-center/builds/permissons/${data}`).then(res => res.data)
        yield put({type: types.SET_BUILD_PERMISSIONS, payload: permResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getBuildNets(action) {
    const {data} = action
    try {
        const permResponse = yield axios.get(`${ENDPOINT}/dev-center/builds/nets/${data}`).then(res => res.data)
        yield put({type: types.SET_BUILD_NETS, payload: permResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}


export function* buildSaga() {
    yield all([
        yield takeLatest(types.GET_BUILDS, getBuilds),
        yield takeLatest(types.GET_BUILD, getBuild),
        yield takeLatest(types.GET_BUILD_VALIDATION, getValidationAndReviews),
        yield takeLatest(types.GET_USER_BUILDS, getUserBuildsByProjects),
        yield takeLatest(types.ADD_BUILD, create),
        yield takeLatest(types.ADD_REVIEW, createReview),
        yield takeLatest(types.APPROVE, setApprove),
        yield takeLatest(types.REJECT, setReject),
        yield takeLatest(types.GET_BUILD_PERMISSIONS, getBuildPermissions),
        yield takeLatest(types.GET_BUILD_NETS, getBuildNets)
    ])
}