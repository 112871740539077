import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as protobufActions from "../../actions/protobufActions"
import CustomModal from '../../components/modal'
import {useEffect, useState} from 'react'
import Button from '../../components/button'
// import './abi.css'
import Table from '../../components/table'
import {Form, Input, Tooltip, Select} from 'antd'
import {
  InfoCircleOutlined
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea'
import JSONInput from 'react-json-ide';
import locale from "react-json-editor-ajrm/locale/en";
import * as extensionActions from "../../actions/extensionActions"

const {Option} = Select

const getEmptyAbi = () => {
  return {
    contract: '',
    net: '',
    abi: ''
  }
}
function Protobuf(props) {
  const {protobufs} = props
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = Form.useForm();
  const {extensions } = props;

  const items = protobufs.map((item, i) => {
    return {...item, number: i + 1}
})



  const columns = [
    {
      width: '3%',
      render: record => <div>
          +
      </div>,
    },
    {   
        dataIndex: 'number',
        width: '17%',        
    },
    {
        dataIndex: 'type',
        width: '50%',
        render: record => <div className="permission-name">
            {record.type}
        </div>
    },
    {
        dataIndex: 'alias',
        width: '30%',
        render: record => <div className="permission-name">
            {record.alias}
        </div>
    }
  ]

  const closeModal = () => {
    setModalVisible(false)
    form.setFieldsValue(getEmptyAbi())
  }

  const onFinish = values => {
    // console.log(values)
    if(values.amino?.error) return
    if(values.params?.error) return
    values.params = values.params.jsObject
    values.amino = values.amino.jsObject
    props.protobufActions.addProtobuf(values)
    closeModal()
  }

  const projectOptions = extensions.map((i,key)=>(
    <Option key={key} value={i.id}>{i.name}</Option>
  ))

  useEffect(() => {
    props.protobufActions.getProtobufs()
    props.extensionActions.getExtensions() 
  }, [])

  return(
      <div className='abi'>
          <div className='abi-head'>
              <div className='abi-title'>
                  Protobuf
              </div>
              <Button type={'primary'} style={{width: '20%'}} position={'end'} onClick={() => setModalVisible(true)}>Add protobuf</Button>         
          </div>
          <Table
            columns={columns}
            items={items}
            rowStyle={{cursor: 'pointer'}}
            expandable
            expandKey={'params'}
            expandColumns={[
              {
                dataIndex: 'name',
                width: '40%',
                render: record => <div className="permission-name">
                    {record.name}
                </div>,
              },
              {
                dataIndex: 'type',
                width: '25%',
                // render: record => <div className="permission-name">
                //     {record.type}
                // </div>,
              },
            ]}
          />
           <CustomModal
                isVisible={modalVisible}
                onCancel={closeModal}
                title={'Add new protobuf'}
            >
                <Form
                    style={{padding: '0 150px'}}
                    name="add-validator"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                    flex: 1,
                    }}  
                    form={form}
                    onFinish={onFinish}
                >
                    <div className='form-group-label-group'>
                        <div className="extension-label">Type* </div> 
                        <Form.Item name={"type"} rules={[{ required: true }]}>
                        <Input
                            placeholder="Input type"
                            suffix={
                            <Tooltip title="Type">
                                <InfoCircleOutlined />
                            </Tooltip>
                            }
                        />
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Alias* </div> 
                        <Form.Item name={"alias"} rules={[{ required: true }]}>
                        <Input
                            placeholder="Input alias"
                            suffix={
                            <Tooltip title="Alias">
                                <InfoCircleOutlined />
                            </Tooltip>
                            }
                        />
                        </Form.Item>
                    </div>
                    {/* <div className='form-group-label-group'>
                        <Form.Item name={"params"}>
                            <TextArea
                            rows={15}  
                            placeholder="Input params"
                            />
                        </Form.Item>
                    </div> */}
                    <div className='form-group-label-group'>
                      <div className="extension-label-before">Params* </div> 
                        <Form.Item name={"params"}>
                          
                        <JSONInput
                          id={1}
                          placeholder={{
                            		"sender": {
                            			"name": "sender",
                            			"type": "string",
                            			"encode": {
                            				"method": "uint32",
                            				"byte": 10
                            			},
                            			"package": false
                            		},
                            		"routes": {
                            			"name": "routes",
                            			"type": "object[]",
                            			"encode": {
                            				"method": "uint32",
                            				"byte": 38
                            			},
                            			"structure": {
                            				"packageName": "SwapAmountOutRoute",
                            				"params": [
                            					{
                            						"name": "pool_id",
                            						"type": "integer",
                            						"encode": {
                            							"method": "uint32",
                            							"byte": 10
                            						},
                            						"package": false,
                            					},
                            					{
                            						"name": "token_in_denom",
                            						"type": "string",
                            						"encode": {
                            							"method": "uint32",
                            							"byte": 18
                            						},
                            						"package": false
                            					},
                            				],
                            			},
                            			"package": true
                                  
                            		},
                            		"token_in_max_amount": {
                            			"name": "token_in_max_amount",
                            			"type": "string",
                            			"encode": {
                            				"method": "uint32",
                            				"byte": 26
                            			},
                            			"package": false
                            		},
                            		"token_out": {
                            			"name": "token_out",
                            			"type": "object",
                            			"encode": {
                            				"method": "uint32",
                            				"byte": 34
                            			},
                            			"structure": {
                            				"packageName": "Coin",
                            				"params": [
                            					{
                            						"name": "denom",
                            						"type": "string",
                            						"encode": {
                            							"method": "uint32",
                            							"byte": 8
                            						},
                            						"package": false,
                            					},
                            					{
                            						"name": "amount",
                            						"type": "string",
                            						"encode": {
                            							"method": "uint32",
                            							"byte": 18
                            						},
                            						"package": false,
                            					}
                            				]
                            			},
                            			"package": true
                               
                            }}
                          }
                          theme="light_mitsuketa_tribute"
                          locale={locale}
                          height="550px"
                          width="100%"
                          onKeyPressUpdate="true"
                          waitAfterKeyPress='100'
                        />
                        </Form.Item>
                    </div>
                    <div className='form-group-label-group'>
                      <div className="extension-label-before">Amino* </div> 
                        <Form.Item name={"amino"}>
                          
                        <JSONInput
                          id={1}
                          placeholder={{
                            		"value": [
                            			"sender", 
                            			"routes",
                            			"token_in_max_amount",
                            			"token_out"
                            		]
                          }}
                          theme="light_mitsuketa_tribute"
                          locale={locale}
                          height="250px"
                          width="100%"
                          onKeyPressUpdate="true"
                          waitAfterKeyPress='100'
                        />
                        </Form.Item>
                    </div>
                    {/* <div className='form-group-label-group'>
                        <Form.Item name={"amino"}>
                            <TextArea
                            rows={15}  
                            placeholder="Input amino"
                            />
                        </Form.Item>
                    </div> */}
                     <div className='form-group-label-group'>
                        <div className="extension-label">Project* </div> 
                        <Form.Item name={"project_id"}  rules={[{ required: true }]}>
                          <Select
                            showSearch={false}
                            placeholder="Select a project"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                          >
                          {projectOptions}
                          </Select>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" position={'center'} style={{textTransform: 'none', width: '30%'}}>
                            Save
                            {/* {props.isLoading ? <Spin /> : ""} */}
                        </Button>
                    </Form.Item>
                </Form>
            </CustomModal>
      </div>
  )
}

const mapStateToProps = state => ({
    isLoading: state.protobuf.isLoading,
    protobufs: state.protobuf.protobufs,
    extensions: state.extension.extensions
  })
  
  
  const mapDispatchToProps = dispatch => ({
    protobufActions: bindActionCreators(protobufActions, dispatch),
    extensionActions: bindActionCreators(extensionActions, dispatch)
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Protobuf)