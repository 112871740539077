export const STATUS = {
    review: 'warning',
    approved: 'success',
    rejected: 'error',
    prod: 'success',
}

export const PERMISSION_TYPE_COLORS = {
    building: '#4B4C63',
    push: '#1A53F0',
    messages: '#FF5722'
}

export const EMPTY_PAGE_TEXTS = {
    desc: 'You don’t have descriptions for this project',
    build: 'You don’t have builds for this application',
    permission: 'You don’t have permissions'
}

export const PERMISSION_TYPES = ['building'] //'push', 'messages'