import * as types from './types'

export function getBuilds(data) {
    return {type: types.GET_BUILDS, data}
}

export function getBuild(data) {
    return {type: types.GET_BUILD, data}
}

export function getUserBuildsByProjects() {
    return {type: types.GET_USER_BUILDS}
}

export function create(data, navigate) {
    return {type: types.ADD_BUILD, data, navigate}
}

export function createReview(data) {
    return {type: types.ADD_REVIEW, data}
}

export function getBuildValidation(data) {
    return {type: types.GET_BUILD_VALIDATION, data}
}

export function setApprove(data) {
    return {type: types.APPROVE, data}
}

export function setReject(data) {
    return {type: types.REJECT, data}
}

export function getBuildPermissions(data) {
    return {type: types.GET_BUILD_PERMISSIONS, data}
}

export function getBuildNets(data) {
    return {type: types.GET_BUILD_NETS, data}
}


// export function getExtension(data) {
//     return {type: types.GET_EXTENSION, data}
// }

