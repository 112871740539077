import './input.css'

export default function Input(props) {
    const {type, placeholder, hint, prefix, onChange} = props
    return(
        <div className='input'>
            <div className='input-hint'>{hint}</div>
            <div className='input-inner'>
                {prefix && <div className='input-prefix'>
                  {prefix}
                </div>
                }
                <input 
                    onChange={onChange}
                    type={type}
                    placeholder={placeholder}
                />
            </div>        
        </div>
    )
}