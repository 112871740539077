import * as types from '../actions/types'

const initialState = {
    error: {},
    success: {},
}

export default function errorReducer(state = initialState, action) {
    switch(action.type) {
        case types.ERROR: 
            return {...state, error: action.error}
        case types.SUCCESS: 
            return {...state, success: action.success}
        case types.ClEAN_ERROR:
                return {...state, error: {}, success: {}}
        default:
            return state; 
    }
}