import '../list.css'

export default function ListItem({title, description, actions, type, style, typeStyle}) {
    return(
        <div className='list-item'>
            <div className='list-header'>
                <div className="list-title">
                    {title}
                </div>
                <div className='list-right'>
                    <div className="list-type" style={{color: typeStyle}}>
                        {type}
                    </div>
                    <div className="list-actions">
                        {actions}
                    </div>
                </div>
                
            </div>
            <div className="list-description">
                {description}
            </div>
        </div>
    )
}