import {all, put, takeLatest} from "redux-saga/effects"
import * as types from "../actions/types"
import axios from 'axios'

import { ENDPOINT } from "../config"

function* getProjects(action) {
    try {
        let buildsResponse;
        const user = JSON.parse(localStorage.getItem('user'));
        if(user.role === 'admin') buildsResponse = yield axios.get(`${ENDPOINT}/dev-center/admin/projects/`).then(res => res.data)
        else buildsResponse = yield axios.get(`${ENDPOINT}/dev-center/projects/`).then(res => res.data)
        yield put({type: types.SET_PROJECTS, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getProject(action) {
    try {
        const {data} = action
        let buildsResponse = yield axios.get(`${ENDPOINT}/dev-center/projects/${data}`).then(res => res.data)
        yield put({type: types.SET_PROJECT, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* create(action) {
    const {data} = action
    try {
        const buildsResponse = yield axios.post(`${ENDPOINT}/dev-center/projects/`, data).then(res => res.data)
        yield put({type: types.ADDED_PROJECT, payload: buildsResponse.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* remove(action) {
    const {data} = action
    try {
        yield axios.delete(`${ENDPOINT}/dev-center/projects/${data}`).then(res => res.data)
        yield put({type: types.DELETED_PROJECT, payload: data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* update(action) {
    const {data} = action
    try {
        const updated = yield axios.put(`${ENDPOINT}/dev-center/projects`, data).then(res => res.data)
        yield put({type: types.UPDATED_PROJECT, payload: updated.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getNets() {
    try {
        const nets = yield axios.get(`${ENDPOINT}/dev-center/nets`).then(res => res.data)
        yield put({type: types.SET_NETS, payload: nets.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}
function* getFormats() {
    try {
        const formats = yield axios.get(`${ENDPOINT}/dev-center/formats`).then(res => res.data)
        yield put({type: types.SET_FORMATS, payload: formats.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* addFormat(action) {
    const {data} = action
    try {
        const format = yield axios.post(`${ENDPOINT}/dev-center/formats`, data).then(res => res.data)
        yield put({type: types.FORMAT_ADDED, payload: format.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getProjectPermissions(action) {
    const {data} = action
    try {
        const permissions = yield axios.get(`${ENDPOINT}/dev-center/permissions/${data}`).then(res => res.data)
        yield put({type: types.SET_PROJECT_PERMISSIONS, payload: permissions.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getProjectNets(action) {
    const {data} = action
    try {
        const net = yield axios.get(`${ENDPOINT}/dev-center/nets/${data}`).then(res => res.data)
        yield put({type: types.SET_PROJECT_NETS, payload: net.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getProjectFormats(action) {
    const {data} = action
    try {
        const formats = yield axios.get(`${ENDPOINT}/dev-center/formats/${data}`).then(res => res.data)
        yield put({type: types.SET_PROJECT_FORMATS, payload: formats.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* addNetsToProject(action) {
    const {data} = action
    try {
        yield axios.post(`${ENDPOINT}/dev-center/nets/connect/${data.id}`, {nets: data.nets.map(item => item.net)}).then(res => res.data)
        console.log(data.nets)
        yield put({type: types.ADDED_NETS_TO_PROJECT, payload: data.nets})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* addFormatsToProject(action) {
    const {data} = action
    try {
        yield axios.post(`${ENDPOINT}/dev-center/formats/connect/${data.id}`, {formats_ids: data.formats.map(p => p.id)}).then(res => res.data)
        console.log(data.formats)
        yield put({type: types.ADDED_FORMATS_TO_PROJECT, payload: data.formats})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* addPermissionsToProject(action) {
    const {data} = action
    try {
        yield axios.post(`${ENDPOINT}/dev-center/permissions/connect/${data.id}`, {permissions: data.permissions.map(item => item.id)}).then(res => res.data)
        yield put({type: types.ADDED_PERMISSIONS_TO_PROJECT, payload: data.permissions})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}
function* getToken(action) {
    const {data} = action
    try {
        const token = yield axios.get(`${ENDPOINT}/dev-center/projects/token/${data}`).then(res => res.data)
        yield put({type: types.SET_PROJECT_TOKEN, payload: token.data})
    } catch(error) {
        console.log(error)
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}


export function* projectSaga() {
    yield all([
        yield takeLatest(types.GET_PROJECTS, getProjects),
        yield takeLatest(types.GET_PROJECT, getProject),
        yield takeLatest(types.DELETE_PROJECT, remove),
        yield takeLatest(types.ADD_PROJECT, create),
        yield takeLatest(types.UPDATE_PROJECT, update),
        yield takeLatest(types.GET_NETS, getNets),
        yield takeLatest(types.GET_FORMATS, getFormats),
        yield takeLatest(types.GET_PROJECT_PERMISSIONS, getProjectPermissions),
        yield takeLatest(types.GET_PROJECT_NETS, getProjectNets),
        yield takeLatest(types.GET_PROJECT_FORMATS, getProjectFormats),
        yield takeLatest(types.ADD_NETS_TO_PROJECT, addNetsToProject),
        yield takeLatest(types.ADD_FORMATS_TO_PROJECT, addFormatsToProject),
        yield takeLatest(types.ADD_PERMISSIONS_TO_PROJECT, addPermissionsToProject),
        yield takeLatest(types.GET_PROJECT_TOKEN, getToken),
        yield takeLatest(types.ADD_FORMAT, addFormat),
    ])
}