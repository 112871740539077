import {all} from 'redux-saga/effects'
import { authSaga } from './authSaga'
import { extensionSaga } from './extensionSaga'
import { buildSaga } from './buildSaga'
import { projectSaga } from './projectSaga'
import { permissionSaga } from './permissionSaga'
import { abiSaga } from './abiSaga'
import { protobufSaga } from './protobufSaga'

export default function* rootSaga() {
    yield all([
        authSaga(),
        extensionSaga(),
        buildSaga(),
        projectSaga(),
        permissionSaga(),
        abiSaga(),
        protobufSaga()
    ])
}