import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Input, Form, Tooltip} from 'antd'
import * as authActions from "../../actions/authActions"
import './signin.css'
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import Button from '../../components/button';

function Signin(props) {
    const navigate = useNavigate()
    const  onFinish =  (values) => {
       props.authActions.signIn(values, navigate)       
    };
    
    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if(props.isAuth){
            navigate('/')
        }
    },[navigate, props.isAuth])


    return(
        <div>
            <div className='form-auth'>
                <div className='form-auth-logo'><img className='form-auth-logo-inner'  alt= 'logo' src='img/icons/logo.svg'></img></div>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='form-auth-inner'
                >             
                 <div className='form-group-label-group'>
                        <div className="extension-label">Email * </div> 
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please, enter correct email address!' , type: 'email' }]}
                    >
                        <Input 
                        suffix={
                            <Tooltip title="Email">
                                <MailOutlined />
                            </Tooltip>
                        }
                         placeholder='Enter email'/>
                    </Form.Item>   
                    </div>
                    <div className='form-group-label-group'>
                        <div className="extension-label">Password * </div> 
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                        <Input.Password placeholder='Enter password' 
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            /> 
                        </Form.Item>  
                     </div>
                     <div className='form-group-label-group-center'>
                        <Button htmlType="submit" type={'primary'} position={'center'} hasGap>
                            Sign in
                        </Button>
                    </div>
                    <Link to={'/signup'}>Don't have an account?</Link>
                </Form>
        </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading,
    user: state.auth.user,
    isAuth: state.auth.isAuth
})


const mapDispatchToProps = dispatch => ({
    authActions: bindActionCreators(authActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Signin)