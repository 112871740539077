import * as types from './types'

export function getProjects() {
    return {type: types.GET_PROJECTS}
}

export function getProject(data,) {
    return {type: types.GET_PROJECT, data}
}

export function create(data) {
    return {type: types.ADD_PROJECT, data}
}

export function update(data) {
    return {type: types.UPDATE_PROJECT, data}
}

export function deleteProject(data) {
    return {type: types.DELETE_PROJECT, data}
}

export function getNets() {
    return {type: types.GET_NETS}
}

export function getFormats() {
    return {type: types.GET_FORMATS}
}

export function addFormat(data) {
    return {type: types.ADD_FORMAT, data}
}

export function getProjectPermissions(data) {
    return {type: types.GET_PROJECT_PERMISSIONS, data}
}

export function getProjectNets(data) {
    return {type: types.GET_PROJECT_NETS, data}
}

export function getProjectFormats(data) {
    return {type: types.GET_PROJECT_FORMATS, data}
}

export function addNetsToProject(data) {
    return {type: types.ADD_NETS_TO_PROJECT, data}
}

export function addFormatsToProject(data) {
    return {type: types.ADD_FORMATS_TO_PROJECT, data}
}

export function addPermissionsToProject(data) {
    return {type: types.ADD_PERMISSIONS_TO_PROJECT, data}
}

export function getToken(data) {
    return {type: types.GET_PROJECT_TOKEN, data}
}

