import {all, put, takeLatest} from "redux-saga/effects"
import * as types from "../actions/types"
import axios from 'axios'
import jwt_decode from "jwt-decode"
import setAuthToken from "../helpers/setAuthToken.js"
import { ENDPOINT } from "../config"

function* signUp(action) {
    const {data, navigate} = action
    try {
        const authResponse = yield axios.post(`${ENDPOINT}/dev-center/developers/registration`, data).then(res => res.data)
        const {token, user} = authResponse.data
        const decoded = jwt_decode(token)
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
        setAuthToken(token)
        yield put({type: types.SET_CURRENT_USER, payload: {decoded,user}})
        navigate('/')
    } catch(error) {
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* signIn(action) {
    const {data, navigate} = action
    try {
        const authResponse = yield axios.post(`${ENDPOINT}/dev-center/developers/login`, data).then(res => res.data)
        const {token, user} = authResponse.data
        const decoded = jwt_decode(token)
        localStorage.setItem('token', token)
        localStorage.setItem('user',  JSON.stringify(user))
        setAuthToken(token)
        yield put({type: types.SET_CURRENT_USER, payload: {decoded, user}})
        navigate('/')  
    } catch(error) {
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* getToken() {
    try {
        const authResponse = yield axios.get(`${ENDPOINT}/dev-center/developers/token`).then(res => res.data)
        yield put({type: types.SET_TOKEN, payload: authResponse.data})
    } catch(error) {
        yield put({type: types.ERROR, error: error.response.data?.data || error.message})
    }
}

function* logout(action) {
    const {navigate} = action
    localStorage.clear()
    setAuthToken(false)
    yield put({type: types.SET_CURRENT_USER, payload: {}})
    navigate('/')
}

export function* authSaga() {
    yield all([
        yield takeLatest(types.SIGN_UP, signUp),
        yield takeLatest(types.SIGN_IN, signIn),
        yield takeLatest(types.GET_TOKEN, getToken),
        yield takeLatest(types.LOGOUT, logout)
    ])
}