import Button from '../../components/button'
import CardSelect from '../../components/card-select'
import './project-network.css'
import {ReactComponent as Plus} from '../../assets/plus.svg';
import {ReactComponent as Close} from '../../assets/close.svg';
import { useState } from 'react';
import CustomModal from '../../components/modal';
import Table from '../../components/table';
import Checkbox from "../../components/checkbox";
import { useEffect } from 'react';

export default function ProjectNetwork({nets, projectId, projectActions, allNets, style, bordered}) {
    const [modalVisible, setModalVisible] = useState(false)
    const [selectedNets, setSelectedNets] = useState([])
    

    const netItems = nets.map((network, key) => 
        <CardSelect 
            value={network.net} 
            img={network.logo || `coin/${network.net}.svg`}
            width={style?.cardWidth ? style.cardWidth : 'calc(12.5% - 10px)'}
            hasAction
            key={key}
            style={{ backgroundColor:'#E8F7FF'}}
            onTap={() => 
            projectActions.addNetsToProject(
                {
                    id: projectId, 
                    nets: nets.filter(item => item.net !== network.net)
                })}
        />)

    useEffect(() => {
        // console.log(nets)
        setSelectedNets(nets.map(item => {
            return {
                net: item.net,
                logo: item.logo,
                isDeployed: item.isDeployed
            }
        }))
    }, [nets])

    // console.log(selectedNets)
    
    const columns = [
        {
            width: '8%',
            dataIndex: 'logo',
            render: record => <div className='network-img'>
                <img alt={record.net} src={record.logo || `coin/${record.net}.svg`}/>
            </div>
        },
        {   
            width: '22%',
            dataIndex: 'net',
            render: record => <div className="network-name">
                    {record.net}
            </div>
        },
        {
            width: '70%',
            justify: 'flex-end',
            render: record => <Checkbox style={{backgroundColor: '#C3CEEB'}} value={record} onChange={onChange} checked={selectedNets.some(net => net.net === record.net)}/>
        },
    ]

    // console.log(allNets)

    const onChange = (checked, value) => {
        if (checked) {
            setSelectedNets([...selectedNets, value])
        } else {
            setSelectedNets(selectedNets.filter(p => p.net !== value.net))
        }
    }

    const closeModal = () => {
        setSelectedNets([])
        setModalVisible(false)
    }

    const onSave = (e) => {
        e.preventDefault()
        projectActions.addNetsToProject({id: projectId, nets: selectedNets})
        closeModal()
    }

    const onUnselect = () => {
        setSelectedNets([])
    }

    const onSelect = () => {
        setSelectedNets(allNets)
    }

    return(
        <>
            <div className='project-nets' style={{borderBottom: bordered && '1px dashed #8B9BC7', paddingBottom: '10px'}}>
                {netItems}
                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        setModalVisible(true);
                        }
                        }
                    position={'start'} 
                    noWrapper
                    style={{
                            width: '100%',
                            borderRadius: '4px', 
                            backgroundColor: '#E4ECFF',
                            border: '1px solid #1A53F0',
                            color: '#1A53F0',
                            textTransform: 'none',
                            padding: '6px 8px',
                            fontFamily: 'Panton Medium',
                            fontSize: '17px'}}
                    >
                    <div className="add-network">
                        <Plus fill='#1A53F0' stroke="#1A53F0" />
                    </div>
                    Add network
                </Button>
            </div>
            <CustomModal
                isVisible={modalVisible}
                onCancel={closeModal}
                title={'Add network'}
            >
                <div className='network-actions'>
                    <div className='select-all' onClick={onSelect}>
                       Select all
                    </div>
                    <div className='unselect' onClick={onUnselect}>
                        <span>Unselect all</span>
                        <div className="unselect-icon">
                            <Close fill='#fff' stroke="#fff" />
                        </div>
                    </div>
                </div>
               
                <Table
                    columns={columns}
                    items={allNets}
                    style={{maxHeight: '300px'}}
                >
                </Table>
                <Button 
                    type={'primary'} 
                    position={'center'} 
                    style={{width: '35%'}}
                    onClick={onSave}
                >
                    Save
                </Button>
            </CustomModal>
        </>
            
    )
}